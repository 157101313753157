export default {
  methods: {
    clearApolloCache() {
      const apolloClient = this.$apollo.getClient()
      /**
       * manually clear the cache from the appsync-metadata part of the appsync store
       * it isn't ideal to directly mutate a redux store like this, but it works
       * there doesn't seem to be a standard way for clearing this part of the store otherwise
       * this ensures the appsync-metadata key isn't rehydrated even after local/session storage is cleared
       */
      apolloClient.store.cache.store.getState()['appsync-metadata'].snapshot.cache = {}
      // clearStore clears the appsync key, but not the appsync-metadata key (in local/sessions storage)
      return apolloClient.clearStore()
    }
  }
}
