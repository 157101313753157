<template>
  <span
    :style="{ height, width }"
    class="skeleton-box"
  />
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '1em'
    },
    width: {
      type: String,
      default: '100%'
    }
  }
}
</script>

<style lang="scss">
.skeleton-box {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: rgba($notification-bg, .8);
  margin-top: 2px;

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .5), transparent);
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
</style>
