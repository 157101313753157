<template>
  <form
    role="search"
    class="search-box"
    @submit.prevent
  >
    <span class="search-icon">
      <SvgIcon name="search" />
    </span>
    <input
      v-model="searchString"
      type="search"
      :placeholder="placeholder"
      :aria-label="placeholder ? placeholder : 'Search'"
      @keyup="onInputChanged"
    >
    <div
      v-if="searchString && clearSearchText"
      class="clear-input click-context"
      @click="clearInput"
    >
      <SvgIcon name="close" />
    </div>
    <el-button
      v-if="!updateOnType"
      type="primary"
      class="search-button"
      @click="submitSearch"
    >
      Search
    </el-button>
  </form>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    updateOnType: {
      type: Boolean,
      default: true
    },
    clearSearchText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchString: ''
    }
  },
  methods: {
    onInputChanged(event) {
      if (this.updateOnType || event.key === 'Enter') {
        this.$emit('input', event.target.value)
      }
    },
    submitSearch() {
      this.$emit('input', this.searchString)
    },
    clearInput() {
      this.searchString = ''
      this.$emit('input', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  align-items: stretch;
  position: relative;
  background-color: $axon;

  svg {
    fill: $black;
  }

  .clear-input {
    // In this case, the element is exactly 2.75rem wide.
    // This leaves it in its place while taking up no extra space
    margin-left: -2.75rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .search-icon {
    display: block;
    position: absolute;
    align-self: center;
    margin-left: 1rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  input {
    width: 100%;
    max-height: 3rem;
    border: none;
    padding: 1rem 3rem;
    background-color: transparent;
    border-bottom: 2px solid $cortex;
    color: $black;
    @include text-style('interface', 'small', 'regular');

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear {  display: none; width : 0; height: 0; }
    &::-ms-reveal {  display: none; width : 0; height: 0; }

    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }

    &::placeholder {
      color: $hillcock;
    }

    &:focus {
      outline: none;
      border-color: $dopamine;
    }
  }
}

#app .search-box {
  .search-button {
    min-width: 0;
    @include text-weight('medium');
  }
}
</style>
