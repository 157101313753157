import { VisitStatus, VisitType, FormStatus, VisitWindowType } from '@/utils/constants'
import { displayAbbreviatedDate, displayFullDate } from '@/utils/date'
import {
  ascend,
  defaultTo,
  hasPath,
  prop,
  propEq,
  sortBy,
  sortWith,
  uniq
} from 'ramda'
import { getSingleFormInstance } from '@/utils/form'
import { isParticipantExcluded } from '@/ppmi/participants'

/*
=======================
SINGULAR VISIT
=======================
*/

/**
 * Get all forms in the visit that are required by other forms.
 * If a form is required by another form, it is a "blocking" form.
 * That is, it prevents a user from continuing the visit by locking the form that requires.
 * Blocking forms are unskippable.
 *
 * @param {VisitInstanceWithForms} visit
 * @returns {FormVersion[]} - an array of blocking forms, returned as FormInstances.
 */
export const getBlockingForms = (
  visit,
  visitWindow = undefined
) => {
  // Get all the forms within the supplied visit.
  const formsInVisit = visit?.formVersions || []

  // filter out any optional forms.
  const nonOptionalFormInstances = visit?.formInstances?.filter(
    propEq('isOptional', false)
  )

  // If a form instance details any required form, added it to an array.
  let blockingForms = formsInVisit.flatMap(formVersion => {
    const formInstance = getSingleFormInstance(
      formVersion.id,
      nonOptionalFormInstances
    )
    return formInstance ? formInstance.requiredForms : null
  })

  // retrieve the FormInstance for each unique blocking form
  blockingForms = uniq(blockingForms)
    .reduce((forms, blockingFormId) => {
      const form = visit.formInstances.find(
        formInstance =>
          formInstance.id === blockingFormId &&
          formInstance.status !== FormStatus.COMPLETE
      )
      if (form) forms.push(form)
      return forms
    }, [])
    // add in the visit label to display in required crfs banner
    // add in visitInstanceId to use to open forms
    .map(form => ({
      ...form,
      visitLabel: getVisitLabel(visitWindow),
      visitInstanceId: visit.visitInstance.id
    }))

  return sortBy(prop('position'), blockingForms)
}

/**
 * Flatten & sort the visit objects to make things more readable and easier to work with in our templates.
 * @param {array} - array of visit objects
 * @returns {array} - an array of visits that is easier to work with.
 */
export const formatVisits = visits => {
  const flattenedVisits = visits.map(participantVisit => {
    return {
      ...participantVisit.visitInstance,
      ...participantVisit.visitTemplate,
      instanceID: participantVisit.visitInstance
        ? participantVisit.visitInstance.id
        : null,
      protocolId: participantVisit.protocolId
    }
  })
  return sortVisits(flattenedVisits)
}

/*
 * Get the active visit in a list of visits.
 * The active visit is defined as the next visit in the schedule or the current visit in progress.
 */
export const getActiveVisit = visits => {
  // Check for and return any visits that are in progress.
  const visitsInProgress = getInProgressVisits(visits)
  if (visitsInProgress.length > 0) {
    return { ...visitsInProgress[0], inProgress: true }
  }

  // Check for and return the next visit
  const upcomingVisits = visits.filter(visit => !visit.instanceID)
  if (upcomingVisits.length > 0) {
    return { ...upcomingVisits[0], inProgress: false }
  }
  return {}
}

export const isScreeningVisit = visitTemplate =>
  visitTemplate.visitType && visitTemplate.visitType === VisitType.SCREENING

export const isBaselineVisit = visitTemplate =>
  visitTemplate.visitType && visitTemplate.visitType === VisitType.BASELINE

/**
 * Returns the name of the given visit object.
 * @param {object} visit - a visit object (expected to include a visitTemplate)
 * @returns {string} - the name of the received visit
 */
export const getVisitName = visit => {
  return visit.visitTemplate ? visit.visitTemplate.name : ''
}

/**
 * Retrieve the protocol version for a given visit.
 * @param {object} visit - a visit object
 * @returns {string} - a string of the active protocol version
 */
export const getProtocolVersion = visit => {
  const _visit = defaultTo({}, visit)
  return hasPath(['visitTemplate', 'protocolVersion', 'versionName'], _visit)
    ? _visit.visitTemplate.protocolVersion.versionName
    : ''
}

export const getProtocolName = visit => {
  return visit.protocolName ? visit.protocolName : ''
}

/**
 * Generate a visit "label" using the protocol name and the visit name.
 * @param {object} visit - a visit object
 * @returns {string} - the generated visit label
 */
export const getVisitLabel = visit => {
  const protocolName = getProtocolName(visit)
  const visitName = getVisitName(visit)
  return `${protocolName}: ${visitName}`
}

/**
 * Create a display string of the visit's status
 * @param {object} visit - a visit object
 * @returns {string} - a user-friendly status string
 */
export const getVisitStatusLabel = visit => {
  if (!visit.visitInstance) {
    return 'Not Started'
  }
  if (isVisitInstanceInProgress(visit.visitInstance)) {
    return 'In Progress'
  }
  if (isVisitIntanceComplete(visit.visitInstance)) {
    return displayAbbreviatedDate(visit.visitInstance.endDate)
  }
  return ''
}

/**
 * Is the given visit instance currently in progress?
 * @param {object} visitInstance - a visitInstance object
 * @returns {boolean}
 */
export const isVisitInstanceInProgress = visitInstance => {
  if (!visitInstance) return false
  return visitInstance?.status === VisitStatus.IN_PROGRESS
}

/**
 * Is the given visit instance complete?
 * @param {object} visitInstance - a visitInstance object
 * @returns {boolean}
 */
export const isVisitIntanceComplete = visitInstance => {
  return (
    !!visitInstance.status && visitInstance.status === VisitStatus.COMPLETE
  )
}

/**
 * Get the display date for a given visit instance.
 * For in-progress visits, the start date will be returned
 * For a completed visit, the completed date will be returned.
 * @param {object} visitInstance - a visitInstance object
 * @returns {string} - a date string of the "visit date"
 */
export const getVisitInstanceDisplayDate = visitInstance => {
  if (isVisitIntanceComplete(visitInstance)) {
    return displayFullDate(visitInstance.endDate)
  } else if (isVisitInstanceInProgress(visitInstance) || visitInstance.status === VisitStatus.REOPENED) {
    return getVisitInstanceStartDate(visitInstance)
  }
  return ''
}

/**
 * Get the start date for a given visit instance.
 * @param {object} visitInstance - a visitInstance object
 * @returns {string} - a date string of the "visit date"
 */
export const getVisitInstanceStartDate = visitInstance => {
  return visitInstance.startDate
    ? displayAbbreviatedDate(visitInstance.startDate)
    : ''
}

/**
 * Calculate the completion percentage for the visit provided.
 * Visit completion is calculated with the following rules:
 * - Only required forms are counted toward completion percentage.
 * - An in-progress form counts as 1 "point"
 * - A completed form is counted as 2 "points"
 *
 * @param {array} visitFormInstances - an array of formInstances
 * @returns {number} completion %
 */
export const getVisitCompletionPercent = visitFormInstances => {
  if (!visitFormInstances.length) {
    return 0
  }

  // filter out optional forms.
  const requiredForms = filterByRequiredFormInstances(visitFormInstances)
  // get complete forms from our filtered list
  const completedForms = filterFormInstancesByStatus(
    requiredForms,
    FormStatus.COMPLETE
  )
  const skippedForms = filterFormInstancesByStatus(
    requiredForms,
    FormStatus.NOT_COMPLETING
  )
  // get started forms from our filtered list
  const startedForms = filterFormInstancesByStatus(
    requiredForms,
    FormStatus.IN_PROGRESS
  )

  // calculate progress.
  // each form has a started and completed event, so progress will be equal to forms x 2
  const maxProgress = requiredForms.length * 2
  // completed forms are worth 2 points, in-progress forms are worth 1
  const currentProgress =
    startedForms.length + completedForms.length * 2 + skippedForms.length * 2
  // return progress as a percentage
  return Math.round((currentProgress / maxProgress) * 100)
}

/**
 * Recieve a list of formInstances and return only forms that are required to complete the visit.
 * @param {array} visitFormInstances - an array of formInstances
 * @returns {array} - an array of required formInstances
 */
export const filterByRequiredFormInstances = visitFormInstances => {
  return visitFormInstances.filter(vfi => vfi !== undefined).filter(propEq('isOptional', false))
}

/**
 * Recieve a list of formInstances and return only forms that have the form status specified.
 * @param {array} visitFormInstances - an array of formInstances
 * @param {string} - the form status to match
 * @returns {array} - an array of required formInstances
 */
export const filterFormInstancesByStatus = (visitFormInstances, formStatus) => {
  return visitFormInstances.filter(propEq('status', formStatus))
}

/**
 * Format a visit object for easier use.
 * @param {object} visit
 * @returns {object} visit
 */
export const formatVisit = visit => {
  if (!visit || !visit.visitTemplate) {
    return {}
  }
  return {
    ...visit.visitInstance,
    ...visit.visitTemplate,
    instanceID: visit.visitInstance ? visit.visitInstance.id : null
  }
}

/*
=======================
MULTIPLE VISITS
=======================
*/

/**
 * Visits were originally designed to be sorted by position. However, We cannot assume that the position field
 * can be sorted across schedules. Completed visits can be from different schedules and a completed visit may
 * originate from a different schedule than currently active or in-progress visits. To catch this scenario,
 * visits will be sorted by scheduledDay and have a secondary sort of `position` applied.
 *
 * @returns {array} visits (sorted)
 */
export const sortVisits = visits => {
  const visitsSort = sortWith([
    ascend(prop('scheduledDay')),
    ascend(prop('position'))
  ])
  return visitsSort(visits)
}

/**
 * Filter a list of visits by a given visitStatus
 * @param {array} visits - a list of visits to filter
 * @param {string} visitStatus - the status to filter by.
 * @returns {array} a list of visits with the given status
 */
export const filterVisitsByStatus = (visits, visitStatus) => {
  if (!visitStatus) {
    return sortVisits(visits)
  }
  return sortVisits(
    visits.filter(visit => {
      // fallback, create a combined visit object if it does not already exist.
      if (visit.visitInstance) {
        visit = formatVisit(visit)
      }
      return visit.instanceID && visit.status === visitStatus
    })
  )
}

/**
 * Return a list of in-progress visits.
 * @param {array} visits - a list of visits to filter
 * @returns {array} a list of in-progress visits
 */
export const getInProgressVisits = visits => {
  return filterVisitsByStatus(visits, VisitStatus.IN_PROGRESS)
}

/**
 * Return a list of completed visits.
 * @param {array} visits - a list of visits to filter
 * @returns {array} a list of completed visits
 */
export const getCompletedVisits = visits => {
  return filterVisitsByStatus(visits, VisitStatus.COMPLETE)
}

/*
=======================
VISIT WINDOWS
=======================
*/

/**
 * given a single visit and a visit window
 * return a visit within that visit window that is blocking the given visit
 * if it exists, otherwise return null
 * */
export const getBlockingVisit = (visit, visitWindow) => {
  const thisVisitPosition = visit.position

  // if its the first visit (position 0), nothing blocks it
  if (thisVisitPosition === 0 && !visit.isSubstudy) {
    return null
  }

  const screeningVisitWithoutInstance = visitWindow.visits.find(v => v.visitTemplate.code === 'SC' &&
    (!v.visitInstance ||
      v.visitInstance.status !== VisitStatus.COMPLETE)
  )

  const thisVisitProtocolName = getProtocolName(visit)

  // get visits with the same study within the window
  const matchingVisits = visitWindow.visits
    .filter(_visit => !_visit.isSubstudy)
    .filter(_visit => thisVisitProtocolName === getProtocolName(_visit))

  if (matchingVisits.length) {
    // see if theres a visit before it (position - 1) which is in progress or not started
    const blockingVisit = matchingVisits.find(visit => {
      return (
        visit.position === thisVisitPosition - 1 &&
        (!visit.visitInstance ||
          visit.visitInstance.status === VisitStatus.IN_PROGRESS)
      )
    })

    return blockingVisit
  } else if (visit.isSubstudy && (!visit.visitInstance ||
    visit.visitInstance.status !== VisitStatus.IN_PROGRESS) &&
    screeningVisitWithoutInstance) {
    // if substudy visit ins't started and screening visit not completed
    return screeningVisitWithoutInstance
  }

  return null
}

/**
 * Take a list of visit windows and return the active window.
 * The active window is any window which has an in_progress visit (can only by one).
 * If no visits are in_progess, the active window is the next occuring date range from the last completed visit window.
 * @param {array} - a list of Visit Window objects
 * @returns {object} - a single, active Visit Window
 */
export const getActiveVisitWindow = visitWindows => {
  // Retrieve any visit windows with in-progress visits
  const visitWindowsInProgress = getInProgressVisitWindows(visitWindows)
  // If found, this is our active visit window.
  if (visitWindowsInProgress.length > 0) {
    return {
      ...visitWindowsInProgress[0],
      inProgress: true
    }
  }

  // If no visits are in-progress, we need to return the next visit.
  const unstartedWindows = getUnstartedVisitWindows(visitWindows)
  // If found, this is our active visit window.
  if (unstartedWindows.length > 0) {
    return {
      ...unstartedWindows[0],
      inProgress: false
    }
  }

  // No active window
  return null
}

export const sortVisitWindows = visitWindows => {
  return sortBy(prop('startDate'))(visitWindows)
}

/**
 * Get unstarted visit windows
 * @param {array} visitWindows
 * @returns {array} - unstarted visit windows
 */
export const getUnstartedVisitWindows = visitWindows => {
  return sortVisitWindows(
    visitWindows.filter(vw => {
      return vw.visits.some(visit => !visit.visitInstance)
    })
  )
}

/**
 * Get upcoming visit windows
 * @param {array} visitWindows
 * @returns {array} - upcoming visit windows
 */
export const getUpcomingVisitWindows = visitWindows => {
  const activeVisitWindowStartDate = getActiveVisitWindow(visitWindows)?.startDate
  return getUnstartedVisitWindows(visitWindows).filter(vw => vw.startDate !== activeVisitWindowStartDate)
}

/**
 * Check if visitWindow has reopened visit
 * @param visitWindow
 * @returns {*}
 */
export const isVisitWindowReopened = visitWindow => {
  return visitWindow.visits.some(
    visit => visit.visitInstance?.status === VisitStatus.REOPENED
  )
}

/**
 * Returns true if any visits in the window are in-progress
 * @param {object} visitWindow
 * @returns {boolean}
 */
export const isVisitWindowInProgress = visitWindow => {
  return visitWindow.visits.some(
    visit =>
      visit.visitInstance && isVisitInstanceInProgress(visit.visitInstance)
  )
}

/**
 * Get in-progress visit windows
 * @param {array} visitWindows
 * @returns {array} - in-progress visit windows
 */
export const getInProgressVisitWindows = visitWindows => {
  const inProgVWs = visitWindows.filter(vw => isVisitWindowInProgress(vw))
  return sortVisitWindows(inProgVWs)
}

/**
 * Get completed visit windows
 * @param {array} visitWindows
 * @param {object} participant
 * @returns {array} - completed visit windows
 */
export const getCompletedVisitWindows = (visitWindows, participant) => {
  return sortVisitWindows(
    visitWindows.filter(vw => {
      // If participant is `excluded`, 'withdrew` or 'screen_failed` then return all visit
      // windows which are `complete` (eg, screening & baseline visit windows - when one could
      // be completed and the other in-progress or not-started)
      if (isParticipantExcluded(participant)) {
        return vw.visits.some(visit =>
          (visit.visitInstance && isVisitIntanceComplete(visit.visitInstance)) ||
          visit.visitInstance?.status === VisitStatus.REOPENED ||
          visit.visitInstance?.status === VisitStatus.NOT_COMPLETING
        )
      } else {
        return vw.visits.every(visit =>
          (visit.visitInstance && isVisitIntanceComplete(visit.visitInstance)) ||
          visit.visitInstance?.status === VisitStatus.REOPENED ||
          visit.visitInstance?.status === VisitStatus.NOT_COMPLETING
        )
      }
    })
  )
}

/**
 * Generate a label to use when displaying a visit window.
 * @param {object} visitWindow
 * @param protocols
 * @returns {string} A label to display for the visit window
 */
export const getVisitWindowLabel = (visitWindow, protocols) => {
  if (visitWindow.windowType === VisitWindowType.SCREENING_BASELINE) {
    return 'Screening & Baseline'
  }
  const startDate = displayAbbreviatedDate(visitWindow.startDate)
  const endDate = displayAbbreviatedDate(visitWindow.endDate)
  return `${startDate} - ${endDate}`
}

/**
 * Determine if today's date is within the visit window.
 * @param {object} visitWindow
 * @returns {boolean}
 */
export const isWithinVisitWindow = visitWindow => {
  const today = new Date()
  const startOfWindow = new Date(visitWindow.startDate)
  const endOfWindow = new Date(visitWindow.endDate)
  return today >= startOfWindow && today <= endOfWindow
}

/**
 * Determine if today's date is before the end of the visit window.
 * @param {object} visitWindow
 * @returns {boolean}
 */
export const isBeforeEndOfWindow = visitWindow => {
  const today = new Date()
  const endOfWindow = new Date(visitWindow.endDate)
  return today <= endOfWindow
}

/**
 * Find reopened visit in window
 * @param visitWindows
 * @returns {T}
 */
export const getReopenedVisitWindow = visitWindows => {
  return visitWindows.find(window => {
    return window.visits.find(visit => {
      return visit?.visitInstance?.status === VisitStatus.REOPENED
    })
  })
}
/**
 * get reopened visit instance
 * @param window
 * @returns {*}
 */

export const getReopenedVisitInstance = window => {
  return window?.visits.find(visit => {
    return visit?.visitInstance?.status === VisitStatus.REOPENED
  })
}
/**
 *  get in progress visit window
 * @param window
 * @returns {*}
 */
export const getInProgressVisitInstance = window => {
  return window?.visits.find(visit => {
    return visit?.visitInstance?.status === VisitStatus.IN_PROGRESS
  })
}
