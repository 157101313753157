<template>
  <span
    v-if="order"
    :class="['caret-icon', { 'caret-up': order === 'ascending' }]"
  >
    <SvgIcon name="chevron-down" />
  </span>
</template>

<script>
export default {
  props: {
    order: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .caret-icon {
    float: right;
    position: relative;
    width: 1rem;

    svg {
      position: absolute;
      top: -4px;
      left: -4px;
      width: 1.5rem;
      height: 1.5rem;
      transition: transform .2s;
    }

    &.caret-up svg {
      transform: rotate(-180deg);
    }
  }
</style>
