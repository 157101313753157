import ARCHIVE_FORM_MUTATION from '@/graphql/forms/ArchiveFormInstanceMutation.graphql'

export default {
  methods: {
    removeForm(
      formInstanceToRemove,
      visitInstanceId
    ) {
      return this.$apollo.mutate({
        mutation: ARCHIVE_FORM_MUTATION,
        variables: {
          formInstanceId: formInstanceToRemove.id
        },
        update: async () => {
          // Triggering event so that parent object can be refreshed since apollo
          // caching seems to be not working if objects are updated at a lower level
          await this.$store.dispatch('refreshVisitWindow')
        }
      })
    }
  }
}
