import { mapState } from 'vuex'
import detectModule from '@/mixins/detectModule'

export default {
  computed: mapState(['isOnline']),
  mixins: [detectModule],
  methods: {
    /*
     * Opens an existing instance.
     */
    openFormInstance(
      formInstance,
      visitInstanceId,
      protocolName = undefined
    ) {
      if (!this.isOnline) { return } // cancel action if offline

      const query = {
        pageTitle: formInstance.formVersion.title
      }
      if ((protocolName || '').length) {
        query['protocolName'] = protocolName
      }

      // navigate to the given formInstance
      this.$router.push({
        name: this.isCTMSActive ? 'readOnlyFormDetails' : 'formDetails',
        params: {
          ...this.$route.params,
          visitInstanceId: visitInstanceId ?? this.$route.params.visitInstanceId,
          formInstanceId: formInstance.id
        },
        query
      })
    }
  }
}
