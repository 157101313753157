import GET_RESTRICTED_PARTICIPANT from '@/graphql/participants/GetRestrictedParticipant.graphql'
import GET_UNRESTRICTED_PARTICIPANT_QUERY from '@/graphql/participants/GetUnrestrictedParticipantQuery.graphql'
import detectModule from '@/mixins/detectModule'
import {
  getParticipantEnrollmentSubgroups,
  getParticipantNameOrPPMIId,
  getParticipantScheduleBaseDate,
  getParticipantStatus,
  isParticipantExcluded
} from '@/ppmi/participants'
import { logError } from '@/utils/logging'
import { propOr } from 'ramda'

export default {
  mixins: [detectModule],
  apollo: {
    participant() {
      return {
        query: this.isEDCActive ? GET_UNRESTRICTED_PARTICIPANT_QUERY : GET_RESTRICTED_PARTICIPANT,
        fetchPolicy: 'no-cache',
        variables: {
          participantId: this.$route.params.participantId,
          studyId: this.$route.params.studyId
        },
        update: (data) => {
          return propOr(data.getParticipantRestricted, 'getParticipantUnrestricted')(data)
        },
        error (error) {
          logError(error, 'ParticipantDetail.vue participant query')
        },
        skip() {
          return !this.$route.params.participantId || !this.$route.params.studyId
        }
      }
    }
  },
  data() {
    return {
      participant: { customerDefinedId: '', sex: '' }
    }
  },
  computed: {
    /**
     * Show the participant's full name or PPMI ID (depending on active module)
     * @returns {string}
     */
    fullNameOrPPMIPlusId() {
      return getParticipantNameOrPPMIId(this.participant)
    },

    /**
     * Exposes the participant's enrollment status.
     * @returns {string} - the participant status
     */
    participantStatus() {
      return getParticipantStatus(this.participant)
    },

    /**
     * Has the participant been excluded from the study?
     * @returns {boolean}
     */
    isParticipantExcluded() {
      return isParticipantExcluded(this.participant)
    },

    /**
     * Retrieve the participant's calculated schedule base date.
     * This date is used for calculating a schedule's visit windows.
     * @returns {string} - the schedule base date, represented as a date string
     */
    participantScheduleBaseDate() {
      return getParticipantScheduleBaseDate(this.participant)
    },

    /**
     * Exposes the participant's enrollment subgroups as a string.
     * @returns {string} - comma seperated list of enrollment subgroups
     */
    participantSubgroups() {
      return getParticipantEnrollmentSubgroups(this.participant)
    }
  }
}
