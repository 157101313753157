<template>
  <SvgIcon
    class="status"
    :class="`status--${status}`"
    :name="statusIcon"
  />
</template>
<script>
import { VisitStatus, FormStatus, ParticipantProtocolStatus } from '@/utils/constants'

export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  computed: {
    /*
     * Return the appropriate icon for each status
     * Unified icons across form and visit statuses
     */
    statusIcon() {
      /* TODO: handle these cases
      if (this.isFormLocked(form)) {
        return 'locked'
      }
      */
      switch (this.status) {
        case '':
        case FormStatus.NOT_STARTED:
          return 'not-started'
        case VisitStatus.COMPLETE:
        case FormStatus.COMPLETE:
          return 'checkmark-filled'
        case ParticipantProtocolStatus.ENROLLED:
          return 'checkmark'
        case FormStatus.IN_PROGRESS:
        case VisitStatus.IN_PROGRESS:
        case ParticipantProtocolStatus.SCREENED:
          return 'partial-crf'
        case FormStatus.NOT_COMPLETING:
        case VisitStatus.NOT_COMPLETING:
        case ParticipantProtocolStatus.EXCLUDED:
        case ParticipantProtocolStatus.SCREEN_FAILED:
          return 'incomplete'
        case ParticipantProtocolStatus.WITHDREW:
          return 'skipped'
        case ParticipantProtocolStatus.PENDING:
        case ParticipantProtocolStatus.INVITE_PENDING:
          return 'clock'
        case ParticipantProtocolStatus.ACCOUNT_CREATED:
          return 'user'
        default:
          // if not a standardized CRF status, render using the string passed in.
          return this.status
      }
    }
  }
}
</script>
<style lang="scss">
.visit-forms.reopened {
  .status--in_progress {
    fill: $info-deep-purple
  }
}
.status {
  height: 1rem;
  width: 1rem;
  fill: $black;

  &--complete,
  &--checkmark,
  &--enrolled {
    fill: $success;
  }

  &--in_progress {
    fill: $dopastat;
  }

  &--not-started,
  &--user,
  &--account-created {
    fill: $dopamine;
  }

  &--not_completing,
  &--warning,
  &--pending,
  &--clock,
  &--invite-pending {
    fill: $warning;
  }

  &--error,
  &--excluded {
    fill: $error;
  }
}
</style>
