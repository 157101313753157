export const logError = (error, message = '') => {
  console.error(message, error)

  // manually throw error for safari/ie
  // eslint-disable-next-line max-len
  // https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api#browser-limits
  try {
    throw error
  } catch (err) {
    window.newrelic.noticeError(err, { passedMessage: message })
  }
}

/**
 * Santizes a GraphQL message so that the string can be used within the application.
 * @param {string} message
 */
export const sanitizeGQLError = message => {
  return message.replace('GraphQL error: ', '')
}
