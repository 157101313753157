/**
 * Custom validations that extend vee-validate.
 */
import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'Email is not formatted correctly'
})

extend('password', {
  validate(value) {
    return (
      // at least 8 characters long
      value.length >= 8 &&
      // has an uppercase character
      /[A-Z]/.test(value) &&
      // has a lowercase character
      /[a-z]/.test(value) &&
      // has a special character
      // Special characters taken from:
      // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
      /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/.test(value) &&
      // has a digit
      /[0-9]/.test(value)
    )
  },
  message: 'Password does not meet security requirements'
})

extend('sameAsPassword', {
  params: ['targetValue'],
  validate(value, { targetValue }) {
    return value === targetValue
  },
  message: 'This field does not match Password'
})

extend('sameAsCohort', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Cohorts must match'
})

// TODO: use mustBeOnly field
extend('onlySubgroup', {
  params: ['subgroupId'],
  validate: (value, { subgroupId }) => {
    if (value.length <= 1) {
      return true
    }
    return value.length > 1 && value.indexOf(subgroupId) === -1
  },
  // This message could be customized in the future.
  message: 'Participant cannot have a genetic mutation and be classified as sporadic.'
})

// This causes form fields to only become invalid on blur/change, but they can
// become valid again on any key press
setInteractionMode('eager')

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
