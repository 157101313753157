const awsmobile = {
  aws_appsync_graphqlEndpoint: `${process.env.VUE_APP_APPSYNC_ENDPOINT}`,
  aws_appsync_region: `${process.env.VUE_APP_AWS_REGION}`,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  Auth: {
    region: `${process.env.VUE_APP_AWS_REGION}`,
    userPoolId: `${process.env.VUE_APP_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID}`,
    mandatorySignIn: true
  }
}

export default awsmobile
