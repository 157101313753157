/**
 * Flash Message Vuex Module
 * Flash messages are used to display messages between pages.
 */
const flashMessageModule = (function() {
  // setup a flash array
  let flash = []
  const state = {}

  Object.defineProperty(state, 'flashMessages', {
    enumerable: true,
    get: function() {
      return flash.map(({ type, message }) =>
        ({
          type: type,
          message: message
        })
      )
    }
  })

  const mutations = {
    CLEAR_FLASH_MESSAGES() {
      flash = flash.filter(({ page }) => page === window.location.pathname)
    },
    PUSH_FLASH_MESSAGE(state, payload) {
      flash.push({
        type: 'success',
        message: payload,
        page: window.location.pathname
      })
    },
    PUSH_FLASH_ERROR(state, payload) {
      flash.push({
        type: 'error',
        message: payload,
        page: window.location.pathname
      })
    }
  }

  const actions = {
    clearFlash: ({ commit }) => {
      commit('CLEAR_FLASH_MESSAGES')
    },
    flash: ({ commit }, payload) => {
      commit('PUSH_FLASH_MESSAGE', payload)
    },
    flashError: ({ commit }, payload) => {
      commit('PUSH_FLASH_ERROR', payload)
    }
  }

  return {
    state,
    mutations,
    actions
  }
})()

export default flashMessageModule
