<template>
  <Modal
    v-show="displayModal"
    class="offline-modal"
    @close="closeModal"
  >
    <template v-slot:title>
      <SvgIcon
        height="16"
        width="16"
        class="warning-icon"
        name="warning"
      />
      You're currently offline
    </template>
    <template v-slot:content>
      <p>
        You're currently offline. Returning to this page will only be possible once your
        connection is restored.
      </p>
    </template>
    <template v-slot:actions>
      <el-button
        type="outline"
        @click="continueExit"
      >
        Continue
      </el-button>
      <el-button
        type="primary"
        @click="closeModal"
      >
        Return to page
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'

export default {
  components: {
    Modal
  },
  props: {
    online: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      displayModal: false,
      routeTo: null
    }
  },
  watch: {
    online(isOnline, wasOnline) {
      // close the modal if we get back online
      if (this.displayModal && !wasOnline && isOnline) {
        this.closeModal()
      }
    }
  },
  created () {
    /*
     * This will provide a warning message when an offline user
     * tries to navigate away. Unfortunately, since browsers want
     * to minimize popups and spammy content, we can merely suggest
     * what message a browser will display and cannot style the alert.
     */
    window.addEventListener('beforeunload', (event) => {
      if (!this.online) {
        event.returnValue = `
          You're currently offline.
          Returning to this page will only be possible once your connection is restored.
        `
      }
      // returning prevents a dialog box from appearing
      return true
    })

    /*
     * This function will interrupt navigation between internal pages with
     * our custom modal, if a user is offline. The prompt allows them to
     * continue or cancel navigation to the next page.
     *
     * I've added this on the component level (opposed to global) for 2 reasons.
     * 1. The functionality will be turned off if the component is not used.
     * 2. We need to interact with the isOnline state, which is easier here.
     */
    this.$router.beforeEach((to, from, next) => {
      if (!this.online) {
        this.next = next
        this.showModal()
      } else {
        next()
      }
    })
  },

  methods: {
    continueExit() {
      this.closeModal()
      this.next()
    },

    showModal() {
      this.displayModal = true
    },

    closeModal() {
      this.displayModal = false
    }
  }
}
</script>
<style lang="scss">
.offline-modal {
  .warning-icon {
    width: 1rem;
    height: 1rem;
    fill: $error;
    margin-right: .75rem;
  }

  p {
    @include text-style('paragraph', 'medium', 'regular')
  }
}
</style>
