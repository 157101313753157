
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VisitTemplate"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VisitTemplate"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"protocolVersion"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProtocolVersion"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"code"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"scheduledDay"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"windowDelta"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isSkippable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"visitType"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":264}};
    doc.loc.source = {"body":"\n#import \"../protocols/ProtocolVersionFragment.graphql\"\n\nfragment VisitTemplate on VisitTemplate {\n    id,\n    protocolVersion {\n        ...ProtocolVersion\n    }\n    name,\n    code,\n    position,\n    scheduledDay,\n    windowDelta,\n    isSkippable,\n    visitType\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../protocols/ProtocolVersionFragment.graphql").definitions));


      module.exports = doc;
    
