<script>
/**
 * AppTile: A component to display a single app in the CDMS App Launcher
 */
export default {
  props: {
    appLink: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    tileAbbr: {
      type: String,
      required: true
    },
    tileColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <router-link
    :to="{ name: appLink }"
    class="app-tile"
    active-class="app-tile--active"
    @click.native="$emit('close')"
  >
    <div
      class="app-icon"
      :style="{ backgroundColor: tileColor }"
    >
      {{ tileAbbr }}
    </div>
    <div>
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.app-tile {
  display: flex;
  padding: 1rem;
  border: 1px solid $cortex;
  border-radius: 2px;
  background-color: $white-matter;
  text-decoration: none;
  text-align: left;
  color: $black;
  cursor: pointer;

  &:hover {
    background-color: $dendrite;
    color: $dopamine;
  }

  &--active {
    padding: calc(1rem - 1px);
    border: 2px solid $black;

    &:hover {
      color: $black;
      background-color: $white-matter;
    }
  }

  &:focus {
    text-decoration: none;
  }

  &:first-child {
    margin-right: 1rem;
  }

  h3 {
    margin: 0;
    @include text-style('interface', 'medium', 'bold');
  }

  p {
    margin: 0;
    margin-top: .25rem;
    color: $black;
    @include text-style('paragraph', 'small', 'regular');
  }

  .app-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: .5rem;
    border-radius: 2px;
    color: $white-matter;
    @include text-style('interface', 'small', 'bold');
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
