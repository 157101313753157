import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'
import { getVisitName } from '@/utils/visit'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    visit() {
      return {
        query: GET_VISIT_QUERY,
        variables() {
          return {
            visitInstanceId: this.$route.params.visitInstanceId,
            studyId: this.$route.params.studyId
          }
        },
        update: data => data.getVisitInstance,
        error (error) {
          logError(error, 'CRFHeader.vue visit query')
        },
        skip() {
          return !this.$route.params.studyId || !this.$route.params.visitInstanceId
        }
      }
    }
  },
  data() {
    return {
      visit: {
        formInstances: []
      }
    }
  },
  computed: {
    visitName() {
      return getVisitName(this.visit) || 'Visit'
    }
  }
}
