<template>
  <BfInput
    class="bf-text-area"
    type="textarea"
    rows="6"
    :spellcheck="$attrs['spellcheck'] ? $attrs['spellcheck'] : true"
    :autocapitalize="$attrs['autocapitalize'] ? $attrs['autocapitalize'] : 'sentences'"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import BfInput from '@/components/BfInput/BfInput'
export default {
  name: 'BfTextArea',
  components: { BfInput }
}
</script>
<style lang="scss">
.bf-text-area {
  textarea {
    font-family: inherit;
    resize: none;
  }
}
</style>
