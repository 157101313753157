<template>
  <div
    class="empty"
    :class="{
      'image-top': imageAboveText,
      'in-table': displayInTable
    }"
  >
    <h5 class="empty__title">
      {{ title }}
    </h5>
    <div
      v-if="$slots.subtitle || subtitle"
      class="empty__subtitle"
    >
      <slot name="subtitle">
        {{ subtitle }}
      </slot>
    </div>
    <figure
      v-if="image"
      :class="`image-${image}`"
    >
      <img
        :src="imageSrc"
        :alt="altText"
      >
    </figure>
    <div class="empty__content">
      <slot />
    </div>
  </div>
</template>

<script>
import formImage from '@/assets/images/emptystate-forms.svg'
import participantImage from '@/assets/images/emptystate-participants.svg'
import pendingParticipantsImage from '@/assets/images/emptystate-pending-participants.svg'
import logFormImage from '@/assets/images/emptystate-log-forms.svg'
import errorImage from '@/assets/images/emptystate-error.svg'
import medicalConditionImage from '@/assets/images/emptystate-medical-conditions.svg'
import noVisitWindows from '@/assets/images/emptystate-visit-windows.svg'

export default {
  props: {
    title: {
      type: String,
      default: 'No Results Found'
    },
    subtitle: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: null,
      validator: val =>
        [
          'participant',
          'pending-participants',
          'form',
          'log-form',
          'error',
          'medical-condition',
          'window',
          null
        ].includes(val)
    },
    imageAboveText: {
      type: Boolean,
      default: false
    },
    displayInTable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageSrc() {
      switch (this.image) {
        case 'participant':
          return participantImage
        case 'pending-participants':
          return pendingParticipantsImage
        case 'form':
          return formImage
        case 'log-form':
          return logFormImage
        case 'error':
          return errorImage
        case 'medical-condition':
          return medicalConditionImage
        case 'window':
          return noVisitWindows
        default:
          return null
      }
    },

    altText() {
      switch (this.image) {
        case 'participant':
          return 'A graphic representing a participant'
        case 'pending-participants':
          return 'A graphic of a clipboard'
        case 'form':
          return 'A graphic of a form'
        case 'log-form':
          return 'An abstract representation of data'
        case 'error':
          return 'A graphic representing an error'
        case 'medical-condition':
          return 'A graphic representing a medical condition'
        case 'window':
          return 'A graphic representing a windows'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
  .empty {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 3rem 0;

    &.in-table {
      margin: 0;
      padding: 3rem 0;
      background-color: $white-matter;
      border: 1px solid $axon;
      border-top: none;
    }

    &__title,
    &__subtitle {
      display: block;
      margin: 0;
      @include text-weight('regular');

      p {
        margin: 0;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__title {
      @include text-style('title', 'small', 'medium');
    }

    &__subtitle {
      @include text-style('paragraph', 'small', 'regular');
      margin: .5rem 0;
    }

    &__content {
      margin-top: 1rem;
    }

    figure {
      width: 12.5rem;
      height: auto;
      max-width: 40%;
    }

    figure.image-window {
      display: flex;
      justify-content: center;
    }

    &.image-top {
      display: grid;
      grid-template: auto / auto;
      justify-items: center;

      figure {
        grid-row: 1;
      }
    }
  }
</style>
