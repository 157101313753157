<template>
  <Modal
    title="Confirm Removing Form"
    class="remove-form-modal"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <p>Removing this form will delete its responses.</p>
      <p>Are you sure you want to remove this form?</p>
    </template>
    <template v-slot:actions>
      <el-button
        type="primary"
        @click="$emit('removeForm')"
      >
        Remove Form
      </el-button>
      <el-button
        type="outline"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal'
export default {
  components: {
    Modal
  }
}
</script>

<style lang="scss">
.remove-form-modal {
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
</style>
