import SET_FORM_INSTANCE_NOT_COMPLETING_MUTATION from '@/graphql/forms/SetFormInstanceNotCompletingMutation.graphql'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'

import { getSingleFormInstance } from '@/utils/form'
import { logError } from '@/utils/logging'
import { uniq } from 'ramda'

export default {
  data() {
    return {
      visitData: {
        formVersions: []
      }
    }
  },
  apollo: {
    visitData () {
      return {
        query: GET_VISIT_QUERY,
        variables: {
          visitInstanceId: this.$route.params.visitInstanceId || this.visitInstanceId
        },
        update: data => data.getVisitInstance,
        error (error) {
          logError(error, 'skipForm.js visit query')
        },
        skip() {
          return !(this.$route.params.visitInstanceId && this.visitInstanceId)
        }
      }
    }
  },
  computed: {
    formsInVisit() {
      return this.visitData.formVersions || []
    },

    /*
     * Creates a list of unskippable forms
     * A form that is required by other forms is unskippable.
     */
    unskippableForms() {
      const requiredForms = this.formsInVisit.flatMap(formVersion => {
        // formInstances may be defined in the component already. If not, grab the instances here.
        const formInstances = this.formInstances || this.visitData.formInstances
        const formInstance = getSingleFormInstance(formVersion.id, formInstances)
        return formInstance ? formInstance.requiredForms : null
      })
      return uniq(requiredForms)
    }
  },
  methods: {
    skipForm({ formInstanceId, visitInstanceId, reason }) {
      const [mutation, variables] = (() => {
        if (formInstanceId) {
          // If we have a form instance, we just need to update its status to `not_completing`
          return [
            SET_FORM_INSTANCE_NOT_COMPLETING_MUTATION,
            {
              formInstanceId,
              reason: reason.reason
            }
          ]
        }
      })()

      if (reason.comment) {
        variables.comment = reason.comment
      }

      return this.$apollo.mutate({
        mutation: mutation,
        variables: variables,
        update: async () => {
          await this.$store.dispatch('refreshVisitWindow')
        }
      }).catch(error => {
        logError(error, 'skipForm.js skip form')
      })
    },

    /*
     * If the formId is included in our unskippableForms array, it can not be skipped.
     */
    isFormSkippable(formInstanceId) {
      return !this.unskippableForms.includes(formInstanceId)
    }
  }
}
