import Vue from 'vue'
import Router from 'vue-router'
import GET_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'

/* Headers */
import CRFSubNav from '@/components/CRFSubNav/CRFSubNav'

/* Utilities */
import Auth from '@aws-amplify/auth'
import apolloProvider from './vue-apollo'

/* Store */
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /** LOGIN/LOGOUT **/
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "Login" */ './views/auth/Login'),
      meta: {
        showHeader: false,
        authRequired: false,
        pageTitle: 'Login'
      },
      beforeEnter: async (to, from, next) => {
        // If the user is already logged in, redirect them back to the root
        try {
          await Auth.currentAuthenticatedUser()
          next('/')
        } catch (e) {
          next()
        }
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import(/* webpackChunkName: "ForgotPassword" */ './views/auth/ForgotPassword'),
      meta: {
        showHeader: false,
        authRequired: false,
        pageTitle: 'Forgot Password'
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "ResetPassword" */ './views/auth/ResetPassword'),
      meta: {
        showHeader: false,
        authRequired: false,
        pageTitle: 'Reset Password'
      },
      beforeEnter: (to, from, next) => {
        // If there is no code parameter in the query string,
        // redirect back to the login screen
        if (!to.query.code) {
          next('/login')
        } else {
          next()
        }
      }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () =>
        import(
          /* webpackChunkName: "NewUserOnboarding" */ './views/auth/NewUserOnboarding.vue'
        ),
      meta: {
        showHeader: false,
        authRequired: false,
        pageTitle: 'New User Onboarding'
      }
    },
    // This route reuses our onboarding template for federated auth
    {
      path: '/remote',
      name: 'remote',
      component: () =>
        import(
          /* webpackChunkName: "NewUserOnboarding" */ './views/auth/NewUserOnboarding.vue'
        ),
      meta: {
        showHeader: false,
        authRequired: false,
        pageTitle: 'New User Onboarding'
      },
      props: {
        logo: 'ppmi',
        title: 'PPMI',
        redirect: process.env.VUE_APP_IU_REDIRECT
      }
    },
    {
      path: '/online',
      name: 'online',
      component: () =>
        import(
          /* webpackChunkName: "NewUserOnboarding" */ './views/auth/NewUserOnboarding.vue'
        ),
      meta: {
        showHeader: false,
        authRequired: false,
        pageTitle: 'New User Onboarding'
      },
      props: {
        logo: 'ppmi',
        title: 'PPMI Online',
        redirect: process.env.VUE_APP_ONLINE_REDIRECT
      }
    },
    {
      path: '/verify-email',
      name: 'verifyEmail',
      component: () => import(/* webpackChunkName: "VerifyEmail" */ './views/auth/VerifyEmail.vue'),
      meta: {
        showHeader: false,
        authRequired: false,
        pageTitle: 'Verify Email'
      },
      beforeEnter: (to, from, next) => {
        // If there is no code parameter in the query string,
        // redirect back to the login screen
        if (!to.query.code) {
          next('/login')
        } else {
          next()
        }
      }
    },
    // dummy onboarding success for testing
    {
      path: '/onboarding-success',
      name: 'onboarding-success',
      component: () =>
        import(
          /* webpackChunkName: "OnboardingSuccess" */ './views/auth/OnboardingSuccess.vue'
        ),
      meta: {
        showHeader: false,
        authRequired: false,
        pageTitle: 'PPMI Registration'
      }
    },
    /** INDEX **/
    {
      path: '/',
      name: 'index',
      component: () => import(/* webpackChunkName: "Index" */ './views/Index.vue')
    },
    /** STUDIES **/
    {
      path: '/ctms',
      alias: '/ctms/studies',
      name: 'studies',
      component: () => import(/* webpackChunkName: "Studies" */ './views/ctms/Studies'),
      meta: {
        pageTitle: 'Studies'
      }
    },
    /** STUDY OVERVIEW **/
    {
      path: '/ctms/studies/:studyId',
      name: 'studyOverview',
      component: () => import(/* webpackChunkName: "StudyOverview" */ './views/ctms/StudyOverview'),
      meta: {
        pageTitle: 'Study Overview'
      }
    },
    /** STUDY PARTICIPANTS **/
    {
      path: '/ctms/studies/:studyId/participants',
      name: 'studyParticipants',
      component: () => import(/* webpackChunkName: "StudyParticipants" */ './views/ctms/StudyParticipants'),
      meta: {
        pageTitle: 'Study Participants'
      }
    },
    /** PARTICIPANT DETAILS - CTMS **/
    {
      path: '/ctms/studies/:studyId/participants/:participantId',
      name: 'participantDetails',
      component: () => import(/* webpackChunkName: 'ParticipantDetails' */ './views/shared/VisitScheduleSwitch'),
      meta: {
        pageTitle: 'Participant Detail'
      }
    },
    /** VISITS - CTMS **/
    {
      path: '/ctms/studies/:studyId/participants/:participantId/visit/:visitInstanceId',
      name: 'visitDetail',
      component: () => import(/* webpackChunkName: 'VisitDetail' */ './views/shared/VisitDetail'),
      meta: {
        pageTitle: 'Visit Overview'
      }
    },
    /** STUDY USERS **/
    {
      path: '/ctms/studies/:studyId/users/',
      name: 'studyUsers',
      component: () => import(/* webpackChunkName: "StudyUsers" */ './views/ctms/StudyUsers'),
      meta: {
        pageTitle: 'Study Users'
      }
    },
    /** EDC **/
    /** SITE / PARTICIPANT LISTING **/
    {
      path: '/edc',
      name: 'EDCHome',
      components: {
        default: () => import(/* webpackChunkName: 'Participants' */ './views/edc/Participants')
      },
      meta: {
        pageTitle: 'EDC Home'
      }
    },
    /** PENDING PARTICIPANTS **/
    {
      path: '/edc/study/:studyId/site/:siteId/transitioning',
      name: 'EDCPendingParticipants',
      components: {
        default: () => import(/* webpackChunkName: 'PendingParticipants' */ './views/edc/PendingParticipants')
      },
      meta: {
        pageTitle: 'Pending Participants'
      }
    },
    /** ENROLL A PARTICIPANT **/
    {
      path: '/edc/study/:studyId/site/:siteId/enroll',
      name: 'enrollParticipant',
      components: {
        default: () => import(/* webpackChunkName: 'EnrollParticipant' */ './views/edc/EnrollParticipant')
      },
      meta: {
        pageTitle: 'Enroll Participant'
      }
    },
    /** PARTICIPANT DETAIL **/
    {
      path: '/edc/study/:studyId/site/:siteId/participant/:participantId',
      name: 'visitSchedule',
      component: () => import(/* webpackChunkName: 'VisitSchedule' */ './views/shared/VisitScheduleSwitch'),
      meta: {
        pageTitle: 'Visit Schedule'
      }
    },
    {
      path: '/edc/study/:studyId/site/:siteId/participant/:participantId/profile',
      name: 'participantProfile',
      component: () => import(/* webpackChunkName: 'ParticipantProfile' */ './views/shared/ParticipantProfile'),
      meta: {
        pageTitle: 'Participant Profile'
      }
    },
    /** VISITS **/
    {
      path: '/edc/study/:studyId/site/:siteId/participant/:participantId/visit/:visitInstanceId',
      name: 'visitManagement',
      components: {
        default: () => import(/* webpackChunkName: 'VisitManagement' */ './views/shared/VisitDetail')
      },
      meta: {
        pageTitle: 'Visit Overview'
      }
    },
    {
      path: '/edc/participant/:participantId/study/:studyId/site/:siteId/visit-window/:visitWindowId',
      name: 'scheduleOfActivities',
      components: {
        default: () => import(/* webpackChunkName: 'ScheduleOfActivities' */ './views/shared/ScheduleOfActivities')
      },
      meta: {
        pageTitle: 'Schedule of Activities'
      }
    },
    /** FORMS **/
    {
      path: '/edc/study/:studyId/site/:siteId/participant/:participantId/visit/:visitInstanceId/form/:formInstanceId',
      name: 'formDetails',
      components: {
        default: () => import(/* webpackChunkName: 'FormView' */ './views/shared/FormView'),
        subheader: CRFSubNav
      },
      meta: {
        pageTitle: 'Form Details'
      }
    },
    {
      path: '/ctms/studies/:studyId/site/:siteId/participants/:participantId' +
        '/visit/:visitInstanceId/form/:formInstanceId',
      name: 'readOnlyFormDetails',
      components: {
        default: () =>
          import(/* webpackChunkName: 'FormViewReadOnly' */ './views/shared/FormView'),
        subheader: CRFSubNav
      },
      props: {
        default: {
          readOnly: true
        }
      },
      meta: {
        pageTitle: 'Read Only Form'
      }
    },
    /** USER PROFILE **/
    {
      path: '/user/:userId?',
      name: 'userProfile',
      component: () => import(/* webpackChunkName: "UserProfile" */ './views/shared/UserProfile'),
      meta: {
        pageTitle: 'User Profile'
      }
    },
    /** CRF PREVIEWER **/
    {
      path: '/crf-preview/participant/:participantId/visit/:visitInstanceId',
      name: 'crfPreview',
      component: () => import(/* webpackChunkName: "CrfPreview" */ './views/preview/CrfPreview'),
      meta: {
        pageTitle: 'CRF Preview'
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  // Clear any flash messages that aren't intended for the next page
  store.dispatch('clearFlash')
  // clear all toast messages between pages.
  store.dispatch('clearToastMessages')

  // Check the route that we're going to. If the user needs to be logged in to
  // access the route, but isn't logged in, then redirect to the /login page
  if (to.meta.authRequired !== false) {
    try {
      // be sure we are logged in
      await Auth.currentAuthenticatedUser()

      const fromEDC = from.path.startsWith('/edc')
      const toEDC = to.path.startsWith('/edc')
      const fromCTMS = from.path.startsWith('/ctms')
      const toCTMS = to.path.startsWith('/ctms')

      // if we're entering either the EDC or the CTMS, get the user and check their permissions
      if ((!fromEDC && toEDC) || (!fromCTMS && toCTMS)) {
        const thisUser = (await apolloProvider.defaultClient.query({
          query: GET_USER_QUERY
        })).data.getUser
        const hasEDCRoles = Boolean(thisUser.edcRoles && thisUser.edcRoles.length)
        const hasCTMSRoles = Boolean(thisUser.studyManagementRoles && thisUser.studyManagementRoles.length)

        if ((toEDC && !hasEDCRoles) || (toCTMS && !hasCTMSRoles)) {
          // navigate to index which should sort out where you belong
          next('/')
        } else {
          next()
        }
      } else {
        next()
      }
    } catch (e) {
      store.dispatch('updateRedirectPage', to)
      next({
        name: 'login'
      })
    }
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.query.pagetitle) {
    window.document.title = `${to.query.pagetitle} | PPMI CDMS`
  } else if (to.meta.pageTitle) {
    window.document.title = `${to.meta.pageTitle} | PPMI CDMS`
  } else {
    window.document.title = 'PPMI CDMS'
  }

  next()
})

router.afterEach((to, from) => {
  // for new relic to track page views properly
  const i = window.newrelic.interaction()
  i.save()
})

export default router
