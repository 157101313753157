import Vue from 'vue'
import Auth from '@aws-amplify/auth'
import { AWSAppSyncClient } from 'aws-appsync'
import VueApollo from 'vue-apollo'
import appSyncConfig from './aws-exports.js'
import { logError } from '@/utils/logging'
import { asyncSessionStorage } from 'redux-persist/storages'

Vue.use(VueApollo)
const client = new AWSAppSyncClient({
  url: appSyncConfig.aws_appsync_graphqlEndpoint,
  region: appSyncConfig.aws_appsync_region,
  auth: {
    type: appSyncConfig.aws_appsync_authenticationType,
    jwtToken: async () => {
      try {
        return await Auth.currentUserInfo() ? (await Auth.currentSession()).getIdToken().getJwtToken() : null
      } catch (error) {
        logError(error, 'jwtToken error')
      }
    }
  },
  offlineConfig: {
    storage: asyncSessionStorage
  }
}, {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    // plain $apollo.query does not support cache-and-network
    query: {
      fetchPolicy: 'network-only'
    }
  }
})

const appsyncProvider = new VueApollo({
  defaultClient: client
})

export default appsyncProvider
