import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import App from './App'
import router from './router'
import store from './store/store'
import './registerServiceWorker'
import './assets/styles/_element-overrides.scss'
import * as svgicon from 'vue-svgicon'
import './assets/icons'
import './plugins/element.js'
import './plugins/filters.js'
import './plugins/validations.js'
import appsyncProvider from './vue-apollo'
import { logError } from '@/utils/logging'

// Amplify
import Amplify from '@aws-amplify/core'
import awsconfig from '@/aws-exports'

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

Amplify.configure(awsconfig)

Vue.config.productionTip = false

// errorHandler fires when there is an uncaught error in a vue instance, log it
Vue.config.errorHandler = (err, vm, info) => {
  logError(err, info)
}

Vue.use(svgicon, {
  tagName: 'SvgIcon'
})

Vue.use(VueAnalytics, {
  id: 'UA-86081584-4',
  router // Automatically track every page load via vue-router
})

new Vue({
  router,
  store,
  apolloProvider: appsyncProvider,
  created () {
    window.addEventListener('offline', () => {
      store.dispatch('updateOnlineStatus', false)
    })
    window.addEventListener('online', () => {
      store.dispatch('updateOnlineStatus', true)
    })
  },
  render: h => h(App)
}).$mount('#app')
