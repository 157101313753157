import { ValueType, ValidationReplacementType } from '@/utils/constants'
import { formatDateForAPI } from '@/utils/date'
import Ajv from 'ajv'
import { all, any, flatten, groupBy, prop, propEq, sortBy, clone } from 'ramda'
import GET_VISIT_QUERY from '@/graphql/visits/GetVisitQuery.graphql'

/**
 * Gets the associated form instance array using a formId
 * TODO: This will need to be revisited once we sort out how multiple form instances are associated with forms.
 *       One form could possibly have multiple instances.
 * @param {string} formVersionId - the form version ID to search with
 * @param {FormInstance[]} formInstances - an array of form instances
 */
export const getSingleFormInstance = (formVersionId, formInstances) => {
  let matchingInstances = []
  if (formInstances) {
    matchingInstances = formInstances.filter(instance => instance.formVersion.id === formVersionId)
  }
  return matchingInstances.length ? matchingInstances[0] : null
}

export const updateVisitFormStatus = (store, formInstanceId, visitInstanceId, studyId, newStatus) => {
  const data = store.readQuery({
    query: GET_VISIT_QUERY,
    variables: {
      visitInstanceId: visitInstanceId,
      studyId: studyId
    }
  })

  data.getVisitInstance.formInstances.find(propEq('id', formInstanceId)).status = newStatus

  store.writeQuery({
    query: GET_VISIT_QUERY,
    variables: {
      visitInstanceId: visitInstanceId,
      studyId: studyId
    },
    data
  })
}

// Returns an object with keys being categories containing the forms within that category.
// Accepts an array of FormInstances or an object that contains a formVersion property (which holds the category data).
export const getFormsByCategory =
  formInstances => groupBy(formInstance => formInstance.formVersion.category, formInstances)

// Returns an array consisting of just the category names.
export const getFormCategories = formsByCategory => Object.keys(formsByCategory)

// returns an array of form versions in position based order
export const visualFormOrder = formInstances => sortBy(prop('position'), formInstances)

// ensure form data is ordered by position
export const orderQuestionsAndOptions = (formInstance) => {
  /**
   * cloning here since we use this often in the update methods of apollo
   * and its best to not mutate cached apollo results
   */
  const formInstanceCopy = clone(formInstance)
  if (formInstanceCopy.sections) {
    formInstanceCopy.sections.sort((a, b) => a.position - b.position)
    formInstanceCopy.sections.forEach(section => {
      section.questions.sort((a, b) => a.position - b.position)
      section.questions.forEach(question => {
        question.options.sort((a, b) => a.position - b.position)
      })
    })
  }
  return formInstanceCopy
}

export const getValueFromQuestionAnswer = (questionAnswer, optionToValue) => {
  if (questionAnswer) {
    // if a value is set in the answer, its a free text (or similar) field, we just use the plain value
    if (questionAnswer.value) {
      return questionAnswer.value
    // if the answer has an options array, its a static answer field, extract the value from the set option
    } else if (questionAnswer.options && questionAnswer.options.length) {
      return optionToValue(questionAnswer.options[0])
    }
  }
}

export const getOptionIdFromValue = (value, question) => {
  if (!value || !question.options || !question.options.length) {
    return null
  }

  return question.options.find(option => option.value === value).id
}

export const getAllFormQuestions = (form) => {
  if (!form.sections) {
    return null
  }

  return form.sections.flatMap(section =>
    /**
     * sectionInstanceId tacked onto questions here,
     * for convienence in use for question mutations which require the sectionInstanceId
     */
    section.questions.map(question =>
      ({ ...question, sectionInstanceId: section.instanceId })
    )
  )
}

export const isQuestionIncomplete = question => {
  const answerValue = getValueFromQuestionAnswer(question.answer, prop('value'))
  // if no answer object at all, or if one exists and its not skipped and the answer is blank, its incomplete
  return !question.defaultAnswer && (!question.answer ||
    (question.answer && !question.answer.isSkipped &&
      (!answerValue || answerValue.trim() === '')
    )
  )
}

// takes a question object and questionValue and returns an error, if any
export const getQuestionError = (question, questionValue) => {
  // skipped questions and questions with default answer have no errors
  if (
    (question.answer && question.answer.isSkipped) ||
    question.defaultAnswer
  ) {
    return null
  }
  // check the valueType for free_text inputs.
  if (question.displayType === 'free_text') {
    if (question.valueType === ValueType.INTEGER &&
      (isNaN(questionValue) || parseInt(questionValue) !== Number(questionValue))
    ) {
      return 'Must be a whole number.'
    } else if (question.valueType === ValueType.FLOAT && (!questionValue || !questionValue.trim())) {
      return 'Must be a number.'
    }
  }
  // if no previous rules match, check if the question is required and show a generic error
  if ((!questionValue || !questionValue.trim()) && !question.isOptional) {
    return 'You must provide an answer.'
  }
  return null
}

/*
 * Given an array of sections & a questionId, return the matched question object.
 * Flattens the questions into one array to make it easier to search for the question.
 * Returns false if the question is not found.
 */
export const findQuestion = (sections, questionId) => {
  let formQuestions = []
  sections.forEach(section => {
    formQuestions = formQuestions.concat(flatten(section.questions))
  })
  return formQuestions.find(formQuestion => formQuestion.id === questionId)
}

/*************************
 * Question Dependencies *
 *************************/

/*
 * Checks whether or not there are dependent question requirements.
 * Returns true if requirements are met for display (or no requirments exist)
 *
 */
export class DependencyResolver {
  /**
   * A map from question ID to whether the requirements for that question have
   * been met. This is used so that a given question's requirements are only
   * checked once per run-through of dependency checks
   *
   * Map<QuestionId, Boolean>
   */
  requirementsMet
  /**
   * An array of all questions' current state in the form
   * Array<Question>
   */
  formQuestions
  /**
   * A map of question ID to EITHER free text value OR option ID
   * If available, this is used in place of the `answer` field in each question
   * to determine if dependencies are met.
   * Map<String, String>
   */
  formValues

  constructor(formQuestions, formValues) {
    this.requirementsMet = {}
    this.formQuestions = formQuestions
    this.formValues = formValues || null
  }

  /**
   * Check to see if a question's requirements have been met, including
   * recursively checking dependent questions
   *
   * @param {Question} question
   */
  areQuestionRequirementsMet = (question) => {
    if (!this.requirementsMet.hasOwnProperty(question.id)) {
      this._checkQuestionRequirements(question)
    }
    return this.requirementsMet[question.id]
  }

  /**
   * Get a list of all answers on questions that are now hidden
   */
  getHiddenAnswers = () => {
    this.formQuestions.forEach(this._checkQuestionRequirements)

    return this.formQuestions.filter(question =>
      !this.requirementsMet[question.id] && question.answer
    ).map(prop('answer'))
  }

  /**
   * Checks whether the requirements for a question have been met. This is true if:
   * 1) The question has no dependencies
   * 2) The question has dependencies, and:
   *   2a) All/Any of the dependencies have met their requirements, and
   *   2b) All/Any of the required answers are in the right state
   *
   * All results are stored in the `requirementsMet` map for later access
   *
   * SCHEMA REFERENCE:
   *  question.requirements = {
   *    rule: String - informs the consumer how to validate if the question has met requirements
   *    dependentQuestions: [
   *      {
   *        dependentSectionQuestionId: ID!
   *        requiredAnswerOptionIds: [ID!]
   *        requiredAnswerValues: [String!]
   *      }
   *    ]
   *  }
   *
   * @param {Question} question
   * @param {Array<Question>} allQuestions
   */
  _checkQuestionRequirements = (question) => {
    if (!question.requirements) {
      this.requirementsMet[question.id] = true
    } else {
      const dependentQuestions = question.requirements.dependentQuestions

      dependentQuestions.forEach(dq => {
        const dependentQuestion = this.formQuestions.find(propEq('id', dq.dependentSectionQuestionId))
        if (dependentQuestion && !this.requirementsMet.hasOwnProperty(dependentQuestion.id)) {
          this._checkQuestionRequirements(dependentQuestion)
        }
      })

      switch (question.requirements.rule) {
        case 'any_of':
          this.requirementsMet[question.id] = any(this._checkDependentAnswers)(dependentQuestions)
          break
        case 'all_of':
          this.requirementsMet[question.id] = all(this._checkDependentAnswers)(dependentQuestions)
          break
      }
    }
  }

  /**
   * Checks to see if the answer for a dependent question falls within the required answers
   *
   * @param {DependentQuestion} dependentQuestion
   * @param {Array<Question>} allQuestions
   */
  _checkDependentAnswers = (dependentQuestion) => {
    const dependentAnswer = (_ => {
      if (this.formValues) {
        return this.formValues[dependentQuestion.dependentSectionQuestionId]
      } else {
        const question = this.formQuestions.find(propEq('id', dependentQuestion.dependentSectionQuestionId))
        return question ? getValueFromQuestionAnswer(question.answer, prop('id')) : null
      }
    })()

    const requiredAnswers = dependentQuestion.requiredAnswerOptionIds || dependentQuestion.requiredAnswerValues
    const answerMatches = requiredAnswers.includes(dependentAnswer)

    return this.requirementsMet[dependentQuestion.dependentSectionQuestionId] && answerMatches
  }
}

/**
 * Update all of the questions in a form section with a new `areRequirementsMet`
 * property that will be `true` if all of the question's requirements to display
 * have been met, including recursively checking its dependencies, and `false`
 * otherwise.
 *
 * @param {Section} section
 * @param {DependencyResolver|Array<Question>} resolver Either an instantiated
 *        DependencyResolver or a full list of questions in a form against which
 *        to check dependencies
 */
export const getSectionWithRequirements = (section, resolver) => {
  const dependencyResolver = resolver instanceof DependencyResolver
    ? resolver
    : new DependencyResolver(resolver)

  return {
    ...section,
    questions: section.questions
      ? section.questions.map(question => {
        return {
          ...question,
          areRequirementsMet: dependencyResolver.areQuestionRequirementsMet(question)
        }
      })
      : []
  }
}

/************************
 * Question Validations *
 ************************/

/**
 * Update all questions in a form with whether or not their current answer is valid, based on any validation
 * schemas in the questions. A question is always considered valid if it has no answer or if it has no validations
 *
 * @param {Section} section
 */
export const validateSectionQuestions = (section, formValues, assessmentDate) => {
  return {
    ...section,
    questions: section.questions.map(question => {
      const validations = getQuestionValidations(question, formValues, assessmentDate)

      return {
        ...question,
        validations: validations.map(validation => {
          const hasValue = typeof formValues[question.id] !== 'undefined' &&
            formValues[question.id] !== null &&
            formValues[question.id] !== ''

          const questionValue = formatQuestionValue(question, formValues[question.id])

          return {
            ...validation,
            isValid: !hasValue || validate(validation.schema, questionValue)
          }
        })
      }
    })
  }
}

/**
 * Check whether a value is valid for a given JSON Schema
 *
 * @param {Object} schema
 * @param {*} value
 * @returns {Boolean}
 */
function validate(schema, value) {
  const ajv = new Ajv()
  // ajv-keywords is required for many extensions to JSON Schema, including date handling
  require('ajv-keywords')(ajv)

  try {
    return ajv.validate(schema, value)
  } catch (e) {
    console.log(`Error during validation: ${e.message}`, schema, value)
    return true
  }
}

function getQuestionValidations(question, formValues, assessmentDate) {
  const validations = question.validations || []

  try {
    return validations.map(validation => {
      let runValidation = true
      const schema = typeof validation.schema === 'object' ? validation.schema : JSON.parse(validation.schema)
      const replacements = validation.replacements || []

      replacements.forEach(replacement => {
        let replacementValue

        switch (replacement.replacementType) {
          case ValidationReplacementType.IN_FORM_ANSWER:
            if (formValues.hasOwnProperty(replacement.questionId)) {
              replacementValue = formValues[replacement.questionId]
            }
            break
          case ValidationReplacementType.IN_FORM_ASSESSMENT_DATE:
            if (assessmentDate) {
              replacementValue = assessmentDate
            }
            break
        }

        if (replacementValue) {
          // If we have a value to put into the schema, put it in!
          switch (schema.format) {
            case 'date':
              schema[replacement.fieldName] = formatDateForAPI(replacementValue)
              break
            default:
              switch (schema.type) {
                case 'integer':
                  schema[replacement.fieldName] = parseInt(replacementValue)
                  break
                case 'number':
                  schema[replacement.fieldName] = parseFloat(replacementValue)
                  break
                default:
                  schema[replacement.fieldName] = replacementValue
                  break
              }
              break
          }
        } else {
          // Otherwise, don't run this validation
          runValidation = false
        }
      })

      if (runValidation) {
        return {
          ...validation,
          schema
        }
      } else {
        return null
      }
    }).filter(validation => validation !== null)
  } catch (e) {
    console.log(`Error in form.js getQuestionValidations(): ${e.message}`)
    return []
  }
}

function formatQuestionValue(question, rawValue) {
  let questionValue
  const hasValue = typeof rawValue !== 'undefined' && rawValue !== null && rawValue !== ''

  // By leaving unparseable values unchanged, validation *should* fail,
  // causing the warning/error message to be shown
  if (question.valueType === ValueType.INTEGER) {
    questionValue = isNaN(parseInt(rawValue, 10))
      ? rawValue
      : parseInt(rawValue, 10)
  } else if (question.valueType === ValueType.FLOAT) {
    questionValue = isNaN(parseFloat(rawValue))
      ? rawValue
      : parseFloat(rawValue)
  } else if (question.valueType === ValueType.BOOLEAN) {
    questionValue = !!rawValue
  } else if (question.valueType === ValueType.DATE && hasValue) {
    questionValue = formatDateForAPI(rawValue)
  } else {
    questionValue = rawValue
  }

  return questionValue
}
