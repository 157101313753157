import SET_FORM_INSTANCE_NOT_STARTED_MUTATION from '@/graphql/forms/SetFormInstanceNotStartedMutation.graphql'
export default {
  methods: {
    setFormInstanceNotStarted({ formInstanceId }) {
      return this.$apollo.mutate({
        mutation: SET_FORM_INSTANCE_NOT_STARTED_MUTATION,
        variables: { formInstanceId },
        update: async () => {
          await this.$store.dispatch('refreshVisitWindow')
        }
      })
    }
  }
}
