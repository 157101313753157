var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.filters.length && _vm.isDetailedView)?_c('SearchFilters',{attrs:{"filters":_vm.filters},on:{"filter-updated":_vm.updateSearchFilter}}):_vm._e(),_c('div',{class:("crf-list " + _vm.type)},[_c('div',{staticClass:"top-bar"},[(_vm.showSearchBar)?_c('SearchBox',{attrs:{"placeholder":"Find a CRF","clear-search-text":_vm.type === 'detailed'},on:{"input":function($event){_vm.internalSearchString = $event}}}):_vm._e(),(_vm.type == 'simple')?_c('SvgIcon',{staticClass:"close",attrs:{"name":"close"},on:{"click":_vm.closeDropdown}}):_vm._e()],1),(_vm.isDetailedView && _vm.hasResults)?_c('div',{staticClass:"crf-list__headers"},[_c('div',{staticClass:"table-column form-name",on:{"click":function($event){return _vm.handleSortClick('title')}}},[_vm._v(" Form Name "),_c('Caret',{attrs:{"order":_vm.displayCaret('title')}})],1),_c('div',{staticClass:"table-column form-status",on:{"click":function($event){return _vm.handleSortClick('status')}}},[_vm._v(" Status "),_c('Caret',{attrs:{"order":_vm.displayCaret('status')}})],1),(_vm.canModifyForms)?_c('div',{staticClass:"table-column form-actions"}):_vm._e()]):_vm._e(),(_vm.hasResults)?_c('el-collapse',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"value":_vm.visitStatus === _vm.VisitStatus.IN_PROGRESS || _vm.visitStatus === _vm.VisitStatus.REOPENED || _vm.isFormPreview ?
        _vm.activeExpandedList :
        _vm.openCategories}},_vm._l((_vm.filteredForms),function(forms,category){return _c('el-collapse-item',{key:category,staticClass:"form-category",attrs:{"title":category,"name":category}},_vm._l((_vm.sortForms(forms)),function(form){return _c('div',{key:form.id,class:[
            'form-summary',
            _vm.isActiveForm(form.id) ? 'active-form' : '',
            _vm.isDetailedView ? 'crf-list__row' : '',
            _vm.isFormLocked(form) ? 'locked' : ''
          ],on:{"mouseover":function($event){_vm.tooltipVisible = form.id},"mouseleave":function($event){_vm.tooltipVisible = null}}},[_c('div',{class:['form-name', _vm.isDetailedView ? 'table-column' : '']},[_c('Tooltip',{staticClass:"form-status",attrs:{"content":("This form will be unlocked when the " + (_vm.getRequiredForms(form)) + " is complete."),"condition":_vm.isFormLocked(form) && _vm.tooltipVisible === form.id,"no-offset":true,"placement":"right"}},[_c('StatusIcon',{staticClass:"form-status-icon",attrs:{"status":_vm.isFormLocked(form) ? 'locked' : ("" + (form.status))}})],1),_c('a',{class:['crf-link', {'link-disabled': !_vm.isFormClickable(form)}],attrs:{"href":"javascript:;"},on:{"click":function($event){_vm.isFormClickable(form) ? _vm.handleClickForm(form) : function () { return null; }}}},[_vm._v(" "+_vm._s(form.formVersion.title)+" "),(_vm.isFormOptional(form))?_c('span',{staticClass:"form-is-optional"},[_vm._v(" "+_vm._s(_vm.displayAsNeeded(form))+" ")]):_vm._e()])],1),(_vm.isDetailedView)?_c('div',{staticClass:"table-column form-status"},[_c('span',{class:['status-wrap', {
                  'status-wrap--complete': form.status === _vm.FormStatus.COMPLETE,
                  'status-wrap--not-started': form.status === _vm.FormStatus.NOT_STARTED,
                  'status-wrap--not-completing': form.status === _vm.FormStatus.NOT_COMPLETING,
                  'status-wrap--in-progress': form.status === _vm.FormStatus.IN_PROGRESS
                }]},[_vm._v(" "+_vm._s(_vm.getStatusLabel(form))+" ")])]):_vm._e(),(_vm.isDetailedView && _vm.canModifyForms)?_c('div',{staticClass:"table-column form-actions"},[(_vm.formHasContextOptions(form))?_c('ContextMenu',[(_vm.displayUnlockOption(form))?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.unlockForm(form)}}},[_vm._v(" Unlock form ")]):_vm._e(),(_vm.displayMarkAsDidNotCompleteOption(form))?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.didNotCompleteFormClick(form)}}},[_vm._v("Mark as Did Not Complete")]):_vm._e(),(_vm.canRemoveForm(form))?_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.handleRemoveForm(form)}}},[_vm._v(" Remove From Visit ")]):_vm._e()],1):_vm._e()],1):_vm._e()])}),0)}),1):_c('div',[(!!_vm.visitStatus)?_c('EmptyState',{attrs:{"title":"No Forms Found","image":"form","image-above-text":true,"display-in-table":true}},[_c('template',{slot:"subtitle"},[_c('p',[_vm._v(" Try another search term "),(_vm.hasSearchFiltersApplied)?_c('a',{on:{"click":function($event){return _vm.clearSearchFilters()}}},[_vm._v(" or clear applied filters ")]):_vm._e()])])],2):_c('EmptyState',{attrs:{"title":"Visit Not Started","image":"form","image-above-text":true,"display-in-table":true}},[_c('template',{slot:"subtitle"},[_c('p',[_vm._v(" Click the Start Visit button to begin capturing information for the "+_vm._s(_vm.visitTemplateName)+" visit. ")])])],2)],1)],1),(_vm.skipFormModalVisible)?_c('SkipFormModal',{attrs:{"form":_vm.formToSkip,"form-status":_vm.formToSkip.status},on:{"close":_vm.handleSkipFormModalClose,"skipForm":_vm.handleSkipForm}}):_vm._e(),(_vm.formToRemove)?_c('RemoveFormModal',{on:{"close":function($event){_vm.formToRemove = null},"removeForm":function($event){_vm.removeForm(_vm.formToRemove), _vm.formToRemove = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }