export default {
  methods: {
    /**
     * Determines whether or not the route's endpoint refers to PPMI application.
     */
    isApplicationPPMI() {
      switch (window.location.pathname) {
        case '/online':
        case '/remote':
        case '/onboarding-success':
          return true
        default:
          return false
      }
    }
  }
}
