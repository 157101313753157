import { VisitStatus } from '@/utils/constants'
import { displayAbbreviatedDate } from '@/utils/date'
import { Features } from '../utils/constants'

export default {
  computed: {
    isQueryResolutionSupported() {
      return this.$store.getters.supportedFeatures?.includes(Features.QUERY_RESOLUTION)
    }
  },
  methods: {
    isReopenedVisit(visit) {
      return visit.visitInstance?.status === VisitStatus.REOPENED
    },
    isReopenedVisitTimeLabel(visit) {
      return `Reopened ${displayAbbreviatedDate(visit.visitInstance?.reopenDate)}`
    }
  }
}
