import Vue from 'vue'
import { displayFullDate } from '../utils/date'

Vue.filter('capitalize', value => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('titleCase', value => {
  if (!value) return ''
  value = value.toString()
  return value.replace(/([^\W_]+[^\s-]*) */g, function(word) {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
  })
})

Vue.filter('splitWords', value => {
  if (!value) return ''
  return value.split(/[_-]/).join(' ')
})

Vue.filter('formatDate', value => {
  if (!value) return ''
  return displayFullDate(value)
})
