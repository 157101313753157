<template>
  <div class="search-filters">
    <label>Filter By: </label>
    <el-dropdown
      v-for="filter in filters"
      :key="filter.prop"
      class="search-filter"
      trigger="click"
      placement="bottom-start"
      @command="filterSelected"
      @visible-change="isVisible => handleVisibleChange(isVisible, filter)"
    >
      <a :class="{ active: activeDropdown === filter.prop }">
        <span>{{ filter.selected.name || filter.name | capitalize }}</span>
        <SvgIcon name="chevron-down" />
      </a>
      <el-dropdown-menu
        slot="dropdown"
        class="filter-dropdown"
      >
        <el-dropdown-item
          v-for="option in filter.options"
          :key="option.value"
          class="filter-option"
          :class="{ 'selected': option.value === filter.selected.value }"
          :command="{ 'filter': filter.name, option }"
        >
          <span>{{ getFilterDisplayName(option.name) | capitalize }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeDropdown: null
    }
  },
  methods: {
    /*
     * Pass the selection to the parent component
     */
    filterSelected(command) {
      this.$emit('filter-updated', command)
    },

    /*
     * Set the activeDropdown when a visible-change event occurs.
     * The visible-change event allows us to set an active class on the trigger element.
     */
    handleVisibleChange(isVisible, filter) {
      this.activeDropdown = isVisible ? filter.prop : null
    },

    getFilterDisplayName(name) {
      if (name === 'screen_failed') {
        return 'Screen Failed'
      } else {
        return name
      }
    }
  }
}
</script>

<style lang="scss">
  .search-filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .5rem;
    @include text-style('interface', 'small', 'bold');
  }

  .search-filter {
    margin-left: 1rem;
    cursor: pointer;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $dopamine;
      min-height: 2.75rem;
      text-decoration: none;

      &:hover, &:focus {
        color: $dopamine;
        text-decoration: underline;
      }

      span {
        max-width: 24ch;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    svg {
      width: 2.25rem;
      height: 2rem;
      margin-left: -.125rem;
      fill: $dopamine;
      transition: transform .3s .2s ease-in-out
    }

    .active {
      color: $dopamine !important; // override global focus state
      text-decoration: none !important; // override global focus state

      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .filter-dropdown {
    border: none;
    max-height: 28rem;
    overflow: auto;
    transition: none;
    // Need to add !important in order to prevent an odd "bump" the occurs due to some
    // conflicting styles within Element UI's popper/dropdown.
    transform: translateY(-1rem) !important;
  }

  .filter-option {
    span {
      flex-grow: 1;
    }
  }

  /* overrides */
  body .filter-option {
    min-height: 2.5rem;
  }

</style>
