<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div class="app-launcher">
    <header class="app-launcher__header">
      <h2>App Launcher</h2>
      <button
        class="app-launcher__close"
        aria-label="Close App Launcher"
        @click="$emit('close')"
      >
        <SvgIcon
          name="close"
          height="1rem"
          width="1rem"
        />
      </button>
    </header>
    <div class="app-launcher__content">
      <AppTile
        app-link="studies"
        title="PPMI 2.0 Trial Management"
        description="The primary tool to manage and view all study data collected across all sites."
        tile-abbr="TM"
        tile-color="#04545E"
        @close="$emit('close')"
      />
      <AppTile
        app-link="EDCHome"
        :title="`${EDCStudyName} EDC`"
        description="Collect and manage participant data and view study-related data for your site."
        tile-abbr="EDC"
        tile-color="#ffb000"
        @close="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import AppTile from './AppLauncherAppTile'
import GET_STUDY_QUERY from '@/graphql/studies/GetStudyQuery.graphql'
import { logError } from '@/utils/logging'
import { ppmiClinicStudyName } from '@/utils/constants'

export default {
  name: 'AppLauncher',
  components: { AppTile },
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  apollo: {
    EDCStudyName() {
      return {
        query: GET_STUDY_QUERY,
        variables: {
          studyId: this.user.edcRoles.find(role => role.studyName === ppmiClinicStudyName).studyId
        },
        update: data => data.getStudy.name,
        error (error) {
          logError(error, 'AppLauncher.vue EDCStudyName query')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-launcher {
  width: 45rem;
  max-width: calc(100% - 3rem);
  padding: 1.5rem;
  padding-top: 1.25rem;
  border: 1px solid $cortex;
  background-color: $white-matter;
  @include add-elevation-shadow(md);
  color: $black;

  &__content {
    display: flex;
  }

  &__header {
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      @include text-style('title', 'medium', 'bold');
    }
  }

  &__close {
    padding: 0;
    margin-top: .125rem;
    margin-right: .125rem;
    border: 0;
    background: transparent;
    cursor: pointer;
  }
}
</style>
