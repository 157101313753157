/**
 * This mixin contains functionality for adding/updating search filters to be used
 * when displaying table data.
 *
 * Requirements:
 * `generateFilterOptions` which will call upon buildOptions & buildFilters to set the `filters` Array
 *    This is defined on the parent so that the filters can be customized based on the data displayed.
 */
import { compose, prop, sortBy, toLower } from 'ramda'

const SearchFilterFunctionality = {
  data() {
    return {
      filters: []
    }
  },
  computed: {
    hasSearchFiltersApplied() {
      const filtersApplied = this.filters.filter(searchFilter => searchFilter.selected.value)
      return filtersApplied.length > 0
    }
  },
  methods: {
    /*
     * Builds an array of option objects from a array of values
     * Optionally accepts a format function to format the name/display of the option.
     */
    buildOptions(optionArray, nameFormatter) {
      return optionArray.map(value => ({
        name: nameFormatter ? nameFormatter(value) : value,
        value: value
      }))
    },

    /*
     * Builds a filter object from the received arguments.
     * filterName: The display name for the filter
     * propIdentifier: value to be matched as a property on the search objects
     * options: an array of options to be used by the filter. an option is an object with name and value properties
     * startingValue: optional default value
     */
    buildFilter(filterName, propIdentifier, options, startingValue = '') {
      // sort the options alphabetically
      const filterOptions = sortBy(compose(toLower, prop('name')))(options)
      // create our filter object
      return {
        name: filterName,
        prop: propIdentifier,
        options: filterOptions,
        selected: {
          value: startingValue
        }
      }
    },

    /*
     * Handle updates to the search filters
     */
    updateSearchFilter({ filter, option }) {
      // reset to page 1
      if (this.pagination) {
        this.pagination.page = 1
      }
      // find the filter to update
      const index = this.filters.findIndex(_filter => _filter.name === filter)
      if (index !== -1 && option.value && this.filters[index].selected.value !== option.value) {
        // if the filter was found, if the value is different update it
        this.filters[index].selected = { ...option }
      } else {
        // if the filter is not found or the current value is selected, reset
        this.filters[index].selected = { name: filter.name, value: '' }
      }
    },

    /*
     * Reset the filters to no selection.
     */
    clearSearchFilters() {
      this.filters = this.filters.map(filter => {
        return {
          ...filter,
          selected: {
            value: ''
          }
        }
      })
    }
  }
}
export default SearchFilterFunctionality
