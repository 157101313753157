export const ModuleLabels = {
  CTMS: 'Trial Management',
  EDC: 'Data Capture'
}

export const FormStatus = {
  NOT_STARTED: 'not_started',
  COMPLETE: 'complete',
  IN_PROGRESS: 'in_progress',
  NOT_COMPLETING: 'not_completing'
}

export const VisitStatus = {
  COMPLETE: 'complete',
  IN_PROGRESS: 'in_progress',
  NOT_COMPLETING: 'not_completing',
  REOPENED: 'reopened',
  COMPLETED_EXTERNALLY: 'completed_externally'
}

export const VisitType = {
  SCREENING: 'screening',
  BASELINE: 'baseline',
  STANDARD: 'standard'
}
export const VisitWindowType = {
  SCREENING_BASELINE: 'screening_baseline'
}

export const ParticipantProtocolStatus = {
  PENDING: 'pending',
  SCREENED: 'screened',
  DECLINED: 'declined',
  // DEPRECATED - left in codebase for backwards compatiblity
  EXCLUDED: 'excluded',
  // replaces `EXCLUDED` status
  SCREEN_FAILED: 'screen_failed',
  ENROLLED: 'enrolled',
  WITHDREW: 'withdrew',
  COMPLETE: 'complete',
  // frontend only statuses derived from business logic
  ACCOUNT_CREATED: 'account-created',
  INVITE_PENDING: 'invite-pending'
}

export const ValueType = {
  TEXT: 'text',
  BOOLEAN: 'boolean',
  INTEGER: 'integer',
  FLOAT: 'float',
  TIME: 'time',
  PARTIAL_DATE: 'partial_date',
  DATE: 'date',
  MEDICATION: 'medication'
}

export const ValidationActionType = {
  WARN: 'warn',
  FAIL: 'fail',
  SCREEN_FAIL: 'screen_fail',
  SCREEN_FAIL_EXTERNAL: 'screen_fail_outside_ie_form'
}

export const ValidationReplacementType = {
  IN_FORM_ANSWER: 'in_form_answer',
  IN_FORM_ASSESSMENT_DATE: 'in_form_assessment_date'
}

export const ReasonForConsent = {
  MIGRATED_PARTICIPANT: 'migrated_participant',
  NEW_PARTICIPANT: 'new_participant',
  PROTOCOL_UPDATE: 'protocol_update',
  PARTICIPANT_SITE_TRANSFER: 'participant_site_transfer'
}

export const EDCPermission = {
  CREATE_FORM_INSTANCE: 'create_form_instance',
  CREATE_SITE_PARTICIPANT: 'create_site_participant',
  CREATE_VISIT_INSTANCE: 'create_visit_instance',
  LIST_FORM_INSTANCES: 'list_form_instances',
  LIST_SITE_PARTICIPANT_VISITS: 'list_site_participant_visits',
  LIST_SITE_PARTICIPANTS: 'list_site_participants',
  LIST_SITE_USERS: 'list_site_users',
  LIST_SITES: 'list_sites',
  LIST_VISIT_INSTANCES: 'list_visit_instances',
  UPDATE_FORM_INSTANCES: 'update_form_instance',
  UPDATE_SITE: 'update_site',
  UPDATE_USER_EDC_ROLE: 'update_user_edc_role',
  UPDATE_VISIT_INSTANCE: 'update_visit_instance',
  VIEW_SITE_DETAILS: 'view_site_details',
  VIEW_STUDY_DETAILS: 'view_study_details'
}

export const CTMSPermission = {
  ASSIGN_EDC_ROLE: 'assign_edc_role',
  ASSIGN_STUDY_MANAGEMENT_ROLE: 'assign_study_management_role',
  CREATE_COHORT: 'create_cohort',
  CREATE_EDC_ROLE: 'create_edc_role',
  CREATE_FORM: 'create_form',
  CREATE_PARTICIPANT: 'create_participant',
  CREATE_PROTOCOL: 'create_protocol',
  CREATE_PROTOCOL_VERSION: 'create_protocol_version',
  CREATE_SCHEDULE: 'create_schedule',
  CREATE_SITE: 'create_site',
  CREATE_STUDY: 'create_study',
  CREATE_STUDY_MANGEMENT_ROLE: 'create_study_management_role',
  CREATE_USER: 'create_user',
  CREATE_VISIT_SKIPPED_REASON: 'create_visit_skipped_reason',
  CREATE_FORM_SKIPPED_REASON: 'create_form_skipped_reason',
  GET_USER_METADATA: 'get_user_metadata',
  LIST_COHORTS: 'list_cohorts',
  LIST_FORMS: 'list_forms',
  LIST_FORM_INSTANCES: 'list_form_instances',
  LIST_PARTICIPANT_VISITS: 'list_participant_visits',
  LIST_PARTICIPANTS: 'list_participants',
  LIST_SCHEDULES: 'list_schedules',
  LIST_SITES: 'list_sites',
  LIST_STUDIES: 'list_studies',
  LIST_STUDY_USERS: 'list_study_users',
  LIST_VISIT_INSTANCES: 'list_visit_instances',
  UNASSIGN_EDC_ROLE: 'unassign_edc_role',
  UNASSIGN_STUDY_MANAGEMENT_ROLE: 'unassign_study_management_role',
  UPDATE_COHORT: 'update_cohort',
  UPDATE_EDC_ROLE: 'update_edc_role',
  UPDATE_PARTICIPANT: 'update_participant',
  UPDATE_PARTICIPANT_SITE: 'update_participant_site',
  UPDATE_SCHEDULE: 'update_schedule',
  UPDATE_SITE: 'update_site',
  UPDATE_STUDY: 'update_study',
  UPDATE_STUDY_MANAGEMENT_ROLE: 'update_study_management_role',
  UPDATE_USER: 'update_user',
  VIEW_FORMS: 'view_forms',
  VIEW_PARTICIPANT_TRANSFER_LOG: 'view_participant_transfer_log'
}

export const inclusionExclusionFormCodes = [
  'INEXHC',
  'INEXHCSC',
  'INEXLRRK2',
  'INEXLRRK2SC',
  'INEXPD',
  'INEXPDSC',
  'INEXPRO',
  'INEXPROSC',
  'INEXSNCA',
  'INEXSNCASC'
]

export const visitWindowReopenReasons = [
  'Data Entry Error',
  'Missing/Incomplete Data',
  'Data Correction',
  'Self-evident Correction',
  'Other'
]

export const asNeeded = '(As Needed)'

export const ppmiClinicStudyName = 'PPMI Clinical'

export const Features = {
  VISIT_WINDOWS: 'visit_windows',
  INVITE_FUNCTIONALITY: 'invite_functionality',
  INVITE_TO_ONLINE: 'invite_to_online',
  SUBSTUDIES: 'substudies',
  QUERY_RESOLUTION: 'query_resolution'
}
