<template>
  <FormFieldWrap
    class="bf-input"
    :class="{ 'locked': $attrs.locked }"
    v-bind="$props"
  >
    <el-input
      :name="name"
      autocomplete="off"
      :spellcheck="$attrs['spellcheck'] ? $attrs['spellcheck'] : false"
      :autocapitalize="$attrs['autocapitalize'] ? $attrs['autocapitalize'] : 'off'"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <SvgIcon
      v-if="$attrs.locked"
      class="lock-icon"
      name="locked"
    />
  </FormFieldWrap>
</template>
<script>
import FormFieldWrap from '../FormFieldWrap/FormFieldWrap'
export default {
  name: 'BfInput',
  components: {
    FormFieldWrap
  },
  props: FormFieldWrap.props
}
</script>
<style lang="scss">
  .bf-input {
    input {
      // Remove any OS-specific styling from the input field
      -webkit-appearance: none;
      border-radius: 0;
      margin: 0;
    }
    .locked .el-input.is-disabled {
      opacity: .4;

      input {
        background-color: $axon;
        border-bottom: 2px solid $cortex;
      }

      &:hover input {
        border-color: $cortex;
      }
    }

    .lock-icon {
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: calc(50% - .5rem);
      bottom: calc(50% - .5rem);
      right: .5rem;
      margin: auto;
      fill: $hillcock;
      cursor: not-allowed;
    }
  }
</style>
