<template>
  <transition name="fade">
    <div :class="['bf-alert', `bf-alert--${type}`]">
      <SvgIcon
        v-if="icon"
        :name="icon"
        width="1rem"
      />
      <span class="visually-hidden">{{ type | capitalize }}:</span>
      <div class="bf-alert__content">
        <slot />
      </div>
      <div
        v-if="closeable"
        class="close"
        @click="$emit('dismiss')"
      >
        <SvgIcon
          name="close"
          width="1rem"
          height="1rem"
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'info'
    },
    icon: {
      type: String,
      default: ''
    },
    closeable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.bf-alert {
  margin-top: 1rem;
  padding: .75rem;
  color: $black;
  @include text-style('interface', 'small', 'medium');
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // If we use markdown in a BfAlert, make sure that the markdown sets the style
  .bf-markdown {
    @include text-style('interface', 'small', 'medium');
  }

  svg {
    align-self: flex-start;
    min-width: 1rem;
    min-height: 1rem;
    margin-right: .5rem;
  }

  &__content {
    flex-grow: 1;
    margin-right: .625rem;
  }

  .close {
    position: relative;
    fill: $black;
    cursor: pointer;

    svg {
      margin-right: 0;
    }

    &:after {
      content: '';
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: -.75rem;
      left: -.75rem;
      cursor: pointer;
    }
  }

  &--info {
    fill: $dopamine;
    background-color: $notification-bg;
  }

  &--warning {
    fill: $warning;
    background-color: $warning-bg;
  }

  &--info-pink{
    fill: $dopamine;
    background-color: $info-purple;
  }

  &--error {
    background-color: $error-bg;
    fill: $error;
  }

  &--success {
    background-color: $success-bg;
    fill: $success;
  }
}
</style>
