<template>
  <FormFieldWrap
    class="bf-select"
    :class="{ 'locked': $attrs.locked }"
    v-bind="$props"
  >
    <span
      v-if="$attrs['override-readonly'] && $attrs.disabled"
      class="readonly"
    >{{ $attrs.value }}</span>
    <el-select
      v-else
      ref="el-select"
      :name="name"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </el-select>
  </FormFieldWrap>
</template>
<script>
import FormFieldWrap from '../FormFieldWrap/FormFieldWrap'
export default {
  name: 'BfSelect',
  components: {
    FormFieldWrap
  },
  props: FormFieldWrap.props,
  methods: {
    /**
     * handleQueryChange calls the internal handleQueryChange on el-select
     * This method is called when a select is "filterable" and the search query
     * is changed, it updates its internal query. There is otherwise no prop that el-select
     * accepts that keeps track of this internal query, hence the need to expose this funciton here.
     *
     * Its used in some custom implimentations of BfSelect (namely, in SearchableSelect and SearchableMultiSelect)
     */
    handleQueryChange(val) {
      this.$refs['el-select'].handleQueryChange(val)
    }
  }
}
</script>
<style lang="scss">
.bf-select {
  position: relative;

  &.has-error {
    // give more padding to account for error icon
    .el-input__inner {
      padding-right: 3.5rem;
    }
  }

  .error-icon {
    // move error icon out of the way of the select caret
    position: relative;
    right: 1.875rem;
  }

  .el-select {
    width: 100%;
    background-color: transparent;

    .el-input__suffix-inner {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
  .el-input .el-select {
    &__caret.el-input__icon {
      font-size: 1rem;
      color: black;
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: $gaba;
        border: 1px solid transparent;
        border-radius: 50%;
        background-color: $dendrite;
      }
     }
  }

  &[disabled] {
    cursor: not-allowed;

    .el-input__suffix {
      cursor: not-allowed;

      &-inner {
        pointer-events: none;
      }
    }

    &.select-field {
      width: initial;
      max-width: calc( 100% - 1.5rem );

      .el-input__suffix {
        display: none;
      }
    }

    .readonly {
      @include text-style('paragraph', 'small', 'regular');
    }
  }

  .locked .el-input.is-disabled {
    opacity: .4;

    input {
      background-color: $axon;
      border-bottom: 2px solid $cortex;
    }

    &:hover input {
      border-color: $cortex;
    }
  }
}

.el-select-dropdown {
  background-color: $axon;
  border: none;
  border-top: 1px solid $axon;
  box-shadow: none;
  margin-top: 0px !important;
  /*
  The way max-width interacts with Element UI's select dropdown has some obscure logic to it:
    + If the select input is wider than the max-width, it will observe the select input's width and
      never surpass it.
    + If the max-width is wider than the select AND the content within is wider than the select, it
      will grow to the specified max-width.

  To put it even more simply, the max-width only comes into play if the content of the dropdown is
  wider than the select input, and the max-width is larger than select input.

  By setting our max-width to the smallest select width we offer, there are no scenarios in which
  the content would extend wider than the width of the input.
  */
  max-width: 16rem;

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    padding: 1rem;
    position: relative;
    overflow: visible;
    height: auto;
    white-space: normal;

    .bf-markdown {
      @include text-style('paragraph', 'small', 'regular');
    }

    &:not(:last-child):after {
      content: '';
      width: calc(100% - 2rem);
      height: 1px;
      background: $axon;
      position: absolute;
      bottom: -1px;
      left: 1rem;
      margin: 0 auto;
      z-index: 1;
    }

    &.selected {
      &:before {
        top: .75rem;
      }
    }
  }
}
</style>
