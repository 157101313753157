<script>
import Dropdown from '@/components/Dropdown/Dropdown'
import CRFList from '@/components/CRFList/CRFList'
import detectModule from '@/mixins/detectModule'
import currentUser from '@/mixins/queries/currentUser'
import participant from '@/mixins/queries/participant'
import visit from '@/mixins/queries/visit'
import { VisitStatus, CTMSPermission } from '@/utils/constants'
import { path, propEq } from 'ramda'
import visitInstanceStates from '@/mixins/visitInstanceStates'
import { userHasStudyPermission } from '../../utils/user'

export default {
  components: {
    Dropdown,
    CRFList
  },
  mixins: [
    detectModule,
    currentUser,
    participant,
    visit,
    visitInstanceStates
  ],
  computed: {
    /**
     * Determine if the user can start or modify forms.
     * @returns {boolean}
     */
    canModifyForms() {
      return !this.isCTMSActive && path(['visitInstance', 'status'], this.visit) === VisitStatus.IN_PROGRESS
    },

    /**
     * Determine if the user can navigate into or view participant forms.
     * @returns {boolean}
     */
    canNavigateForms() {
      return !this.isCTMSActive || userHasStudyPermission(
        this.currentUser,
        this.$route.params.studyId,
        CTMSPermission.VIEW_FORMS)
    },

    /**
     * Returns the formVersion of the active form to pass to CRFList.
     * The form version is used to set an active state on the active form in the list.
     * @returns {object} - form version id of the active form
     */
    activeForm() {
      // search form instances for a matching form instance id
      const activeFormInstance = this.visit.formInstances.find(propEq('id', this.$route.params.formInstanceId))
      // once a form instance is found, use it get the id of the matching form version and return that form version
      return activeFormInstance ? this.visit.formVersions.find(propEq('id', activeFormInstance.formVersion.id)) : null
    }
  },
  methods: {
    // We use the path to determine whether or not to show a breadcrumb link.
    // Why? because $route.params are left on the $route object between page loads.
    // When returning to the ParticipantDetail page and checking params, visitInstanceId is still present.
    pathIncludes(substring) {
      if (this.$route.path) {
        return this.$route.path.includes(substring)
      }
      return false
    },

    handleFormClick() {
      this.$refs.formDropdown.toggle()
    },

    navToSoA() {
      const params = { ...this.$route.params, visitTemplateId: this.visit.visitTemplate.id }
      if (this.isQueryResolutionSupported) {
        this.$router.push({
          name: 'scheduleOfActivities',
          params
        })
      } else {
        this.$router.push({
          name: this.isEDCActive ? 'visitManagement' : 'visitDetail',
          params
        })
      }
    },

    navToParticipantProfile() {
      this.$router.push({
        name: 'participantProfile',
        params: { ...this.$route.params }
      })
    },

    navToVisitSchedule() {
      this.$router.push({
        name: this.isCTMSActive ? 'participantDetails' : 'visitSchedule',
        params: { ...this.$route.params }
      })
    }
  }
}
</script>

<template>
  <nav class="crf-subnav">
    <el-button
      type="ghost"
      class="back-link"
      @click="navToSoA"
    >
      <SvgIcon
        name="chevron-right"
        class="flip"
      />
      Schedule of Activities
    </el-button>

    <div class="dropdown-menus">
      <Dropdown
        ref="formDropdown"
        title="View All Forms"
        :hoverable="false"
        :close-on-click-outside="true"
        orientation="left"
      >
        <CRFList
          type="simple"
          :form-versions="visit.formVersions"
          :form-instances="visit.formInstances"
          :can-navigate-forms="canNavigateForms"
          :can-modify-forms="canModifyForms"
          :loading="false"
          :active-form="activeForm"
          :visit="visit"
          @form-click="handleFormClick"
        />
      </Dropdown>

      <Dropdown
        ref="participantDropdown"
        :title="fullNameOrPPMIPlusId"
        :hoverable="false"
        :close-on-click-outside="true"
        orientation="left"
      >
        <el-dropdown-item
          v-if="isEDCActive"
          @click.native="navToParticipantProfile"
        >
          Participant Profile
        </el-dropdown-item>
        <el-dropdown-item @click.native="navToVisitSchedule">
          Visit Schedule
        </el-dropdown-item>
      </Dropdown>
    </div>
  </nav>
</template>

<style lang="scss">
  .crf-subnav {
    width: 100%;
    margin: 0;
    padding: 0 1rem;
    border-bottom: 1px solid $axon;
    background-color: $white-matter;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dropdown-menus {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .dropdown {
      min-height: 3rem;
      padding: 0 .75rem;
      border-left: 1px solid $axon;

      &:first-of-type {
        padding-left: 1.25rem;
      }

      &:last-of-type {
        padding-right: .25rem;
      }
    }

    .dropdown__title-wrap {
      justify-content: center;
    }

    .dropdown__title {
      padding: 0;
      color: $dopamine;
      @include text-style('interface', 'small', 'medium');
    }

    /* Add a touch more spacing between the dropdown trigger and content */
    .dropdown__content {
      top: calc(100% + .5rem);
    }

    .dropdown__title-wrap,
    .back-link {
      > span > svg {
        fill: $dopamine;
      }
    }
  }

  #app .crf-subnav .el-button--ghost.back-link {
    color: $dopamine;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
</style>
