<template>
  <div
    :class="['form-field', {'has-error': error, 'has-subtext': subtext }]"
    @click="$emit('click')"
  >
    <label
      v-if="labelPosition === 'before'"
      class="input-label"
      :for="name"
      @click="$emit('label-click')"
    >
      {{ label }}
      <span
        v-if="optional"
        class="optional-text"
      >
        (Optional)
      </span>
    </label>
    <span
      v-if="instructions"
      class="field-instructions"
    >
      {{ instructions }}
    </span>
    <div class="field-wrap">
      <slot />
      <div
        v-if="error || icon"
        class="icon-wrapper"
      >
        <SvgIcon
          v-if="error"
          class="input-icon error-icon"
          name="warning-filled"
          width="24"
          height="24"
        />
        <SvgIcon
          v-else-if="icon"
          class="input-icon custom-icon"
          :name="icon"
          :width="iconWidth"
          :height="iconWidth"
        />
      </div>
      <label
        v-if="labelPosition === 'after'"
        class="input-label"
        :for="name"
        @click="$emit('label-click')"
      >{{ label }}</label>
      <SvgIcon
        v-if="locked"
        class="lock-icon"
        name="locked"
      />
    </div>
    <slot name="user-feedback" />
    <div
      v-if="error"
      class="errors"
    >
      <span class="input-error">{{ error }}</span>
    </div>
    <div
      v-if="subtext"
      class="subfield"
    >
      <span class="input-subtext">{{ subtext }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    error: {
      type: String,
      default: ''
    },
    locked: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    instructions: {
      type: String,
      default: ''
    },
    subtext: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconWidth: {
      type: String,
      default: '16'
    },
    labelPosition: {
      type: String,
      default: 'before'
    },
    optional: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fieldId() {
      return this.name ? this.name : encodeURI(this.label)
    }
  }
}
</script>

<style lang="scss">
.form-field {
  position: relative;
  width: 100%;

  input[type="text"] {
    border-radius: 2px !important;
  }

  .lock-icon {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: calc(50% - .5rem);
    bottom: calc(50% - .5rem);
    right: .5rem;
    margin: auto;
    fill: $hillcock;
    margin-top: 0;
  }

  .icon-wrapper {
    pointer-events: none;
    height: 100%;
    width: 2rem;
    position: absolute;
    top: 0;
    right: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .field-wrap {
    position: relative;

    & + * {
      margin-top: .5rem;
    }
  }

  .input-label,
  .field-instructions,
  .errors .input-error,
  .subfield .input-subtext {
    display: block;
    color: $black;
    @include text-style('interface', 'small', 'regular');
    margin-bottom: 4px;
  }

  .field-instructions {
    color: $hillcock;
  }

  .optional-text {
    color: $hillcock;
    font-weight: 400;
  }

  .errors .input-error {
    color: $error;
  }

  .error-icon {
    fill: $error;
  }

  .custom-icon {
    fill: $black;
  }

  // *** Input specific styles ***
  // TODO: can move most input styles from global element-overrides scss file to here
  .el-input, .el-textarea {
    input, textarea {
      padding: .5rem 1rem;
      border: none;
      border-bottom: 2px solid $cortex;
      border-radius: 2px;
      background-color: $axon;
      color: $black;

      &::placeholder {
        color: $hillcock;
      }

      &[type="submit"] {
        cursor: pointer;
        min-width: 12rem;
        height: auto;
        padding: 1rem;
        margin: 1rem 0 0;
        border: none;
        border-radius: 0;
        background-color: $dopamine;
        text-align: left;
        color: $white-matter;
        @include text-weight('medium');

        &:not([disabled]):focus,
        &:not([disabled]):hover {
          border-bottom: none;
          border: initial;
        }
      }

      &:focus,
      &:hover {
        border-color: $dopamine;
      }
    }
    &.is-disabled {
      input,
      textarea {
        color: $black !important;
        @include text-weight('medium');
        background-color: transparent;
        border-bottom: 1px solid $hillcock;
        -webkit-text-fill-color: $black !important; /* Override iOS opacity change affecting text & background color */
        opacity: 1; /* Override iOS opacity change affecting text & background color */

        &::placeholder {
          color: $hillcock;
        }
      }
    }
  }

  &.has-error {
    .el-input, .el-textarea {
      input, textarea {
        border-bottom: 2px solid $error;
      }
    }
  }

  .field-wrap .el-input.is-focus .el-input__inner {
    border-color: $dopamine;
  }
}
</style>
