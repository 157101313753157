<template>
  <el-dropdown
    trigger="click"
    class="context-menu"
    v-bind="$attrs"
    @visible-change="handleVisibleChange"
  >
    <el-button
      type="menu"
      :class="{ 'active' : menuIsVisible }"
    >
      <div class="icon-wrap">
        <SvgIcon name="overflow-menu" />
      </div>
    </el-button>
    <el-dropdown-menu class="context-menu-content">
      <slot />
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  data() {
    return {
      menuIsVisible: false
    }
  },
  methods: {
    handleVisibleChange(isVisible) {
      this.menuIsVisible = isVisible
    }
  }
}
</script>

<style lang="scss">
.context-menu-content {
  max-width: 14.125rem;
  box-shadow: 0px 0px 10px rgba($black, .25);

  .el-dropdown-menu__item {
    justify-content: flex-start;

    svg {
      height: 1rem;
      width: 1rem;
      margin-right: .5rem;
      // line placement fix
      margin-top: 1px;
      margin-bottom: -1px;
    }

    &:hover svg {
      fill: $dopamine;
    }
  }
}

.context-menu {
  width: 3rem;
  height: 3rem;
  display: flex;
  display: inline-flex;
  cursor: pointer;

  .icon-wrap {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .dropdown-item {
    background-color: white;
    color: $black;
    @include text-weight('medium');

    &:not(:last-child) {
      border-bottom: 1px solid $cortex;
    }

    &:hover {
      background-color: $axon;
    }

    &__border-wrap {
      border: none !important;
    }
  }
}

#app {
  table,
  .el-table,
  .table-column,
  .form-section {
    .context-menu .el-button--menu {
      border: none;

      &:not(:disabled):hover,
      &:focus,
      &.active {
        background-color: transparent;

        svg {
          background-color: $axon;
          border-radius: 50%;
        }
      }

      &.active svg {
        fill: $black;
      }
    }
  }
}
</style>
