
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ParticipantProtocol"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ParticipantProtocol"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"protocolId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"reason"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"date"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"scheduleBaseDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"protocol"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"Protocol"},"directives":[]}]}}]}}],"loc":{"start":0,"end":198}};
    doc.loc.source = {"body":"#import \"../studies/ProtocolFragment.graphql\"\n\nfragment ParticipantProtocol on ParticipantProtocol {\n  protocolId,\n  status,\n  reason,\n  date,\n  scheduleBaseDate,\n  protocol {\n    ...Protocol\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../studies/ProtocolFragment.graphql").definitions));


      module.exports = doc;
    
