<template>
  <header class="header">
    <div class="header__blackfynn-logo">
      <SvgIcon
        name="ppmi-logo"
        height="45"
        width="45"
        @click="$router.push('/')"
      />
    </div>
    <div class="header__study-title">
      <Dropdown
        ref="moduleDropdown"
        class="header__module-dropdown"
        :read-only="isAppLauncherDropdownReadOnly"
        :close-on-click-outside="true"
      >
        <template v-slot:title>
          <div class="module-title">
            <span class="module">{{ activeModule || "Select App" }}</span>
            <ApolloQuery
              v-if="isEDCActive && _hasEDCRole"
              class="property"
              tag="span"
              :query="require('@/graphql/studies/GetStudyQuery.graphql')"
              :variables="{ studyId: user.edcRoles.find(role => role.studyName === ppmiClinicStudyName).studyId }"
              :update="data => data.getStudy.name"
            >
              <template v-slot="{ result: { data: studyName }, isLoading }">
                <SkeletonBox v-if="isLoading" />
                <template v-else>
                  {{ studyName }}
                </template>
              </template>
            </ApolloQuery>
            <span
              v-else-if="isCTMSActive"
              class="property"
            >PPMI 2.0</span>
          </div>
        </template>
        <AppLauncher
          :user="user"
          @close="toggleModuleDropdown"
        />
      </Dropdown>
    </div>
    <div class="header__action-icons">
      <div
        class="click-context"
        @click="mailTo"
      >
        <SvgIcon
          id="help-icon"
          name="help"
        />
      </div>
      <Dropdown
        ref="userDropdown"
        class="header__user-dropdown"
        :title="userName"
        :close-on-click-outside="true"
        icon="user"
      >
        <DropdownItem @click.native="goToUserProfile">
          User Settings
        </DropdownItem>
        <DropdownItem @click.native="handleSignoutClick">
          Sign Out
        </DropdownItem>
      </Dropdown>
    </div>
  </header>
</template>

<script>
import Dropdown from '@/components/Dropdown/Dropdown'
import SkeletonBox from '@/components/SkeletonBox/SkeletonBox'
import AppLauncher from '@/components/AppLauncher/AppLauncher'
import GET_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'
import GET_SITE_QUERY from '@/graphql/sites/GetSiteQuery.graphql'
import detectModule from '@/mixins/detectModule'
import utilities from '@/mixins/utilities'
import { logError } from '@/utils/logging'
import { hasEDCRole, hasStudyManagementRole, getUserEDCRole } from '@/utils/user'
import { ppmiClinicStudyName } from '@/utils/constants'

export default {
  name: 'TheHeader',
  components: {
    AppLauncher,
    Dropdown,
    DropdownItem: Dropdown.DropdownItem,
    SkeletonBox
  },
  mixins: [ detectModule, utilities ],
  data() {
    return {
      activeModule: this.detectModule,
      user: {},
      ppmiClinicStudyName
    }
  },
  apollo: {
    user: {
      query: GET_USER_QUERY,
      update: data => data.getUser,
      result () {
        this.$store.dispatch('updateLoading', false)
      },
      fetchPolicy: 'no-cache',
      error (error) {
        logError(error, 'TheHeader.vue get user query')
      },
      // once we have a user, we don't need to keep querying
      skip() {
        return !!(this.user && this.user.id) || this.isApplicationPPMI()
      }
    },

    /**
     * Get the active site and update Vuex.
     * Used for detecting supported feature flags.
     *
     * Note:
     * We are declaring this query directly in the component (as opposed to a mixin)
     * because it has a customized workflow associated with it.
     */
    activeSite() {
      return {
        query: GET_SITE_QUERY,
        variables() {
          return {
            siteId: this.activeSiteId
          }
        },
        update: data => {
          const activeSite = data.getSite
          this.$store.dispatch('updateActiveSite', activeSite)
          return activeSite
        },
        error (error) {
          logError(error, 'site query')
        },
        skip: () => !this.activeSiteId
      }
    }
  },
  computed: {
    userName() {
      if (!this.user.firstName || !this.user.lastName) {
        return ''
      }
      return `${this.user.firstName} ${this.user.lastName}`
    },

    // only allow user to see the app launcher if they have both edc and study management roles
    isAppLauncherDropdownReadOnly() {
      return !(this._hasEDCRole && this._hasStudyManagementRole)
    },

    _hasEDCRole() {
      return this.user && hasEDCRole(this.user)
    },

    _hasStudyManagementRole() {
      return hasStudyManagementRole(this.user)
    },

    /**
     * To support feature flags, we need to know the active site.
     * For the EDC, this can be taken directly from the EDC Role.
     * For the CTMS, it should be taken from the URL since users can bounce between sites.
     *
     * Once this ID is computed, the getSite query will run and update the store with the result.
     */
    activeSiteId() {
      if (this.activeModule && this.user) {
        return this.isEDCActive
          ? getUserEDCRole(this.user).siteId
          : this.$route.params.siteId
      }
      return null
    }
  },

  /*
   * Since TheHeader is outside of vue-router, it gets the root route with no module specified
   * This function ensures that our module state is always up to date.
   */
  watch: {
    $route() {
      // if no module present in the route, keep the current active module
      this.activeModule = this.detectModule ? this.detectModule : this.activeModule
    },

    activeSiteId(newValue) {
      if (newValue === undefined) {
        this.$store.dispatch('updateActiveSite', {})
      }
    }
  },
  methods: {
    mailTo () {
      window.location.href = 'mailto:PPMI_EDChelp@indd.org'
    },
    handleSignoutClick() {
      this.$emit('signout')
    },

    goToUserProfile(e) {
      this.$router.push({
        name: 'userProfile'
      })
      // minimize the dropdown
      this.$refs.userDropdown.toggle()
    },

    toggleModuleDropdown() {
      this.$refs.moduleDropdown.toggle()
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  background-color: $dopamine;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  color: white;
  height: 4.5rem;
  @include text-style('interface', 'small', 'medium');
  @include elevate(sm);
  border-radius: 0;

  &__blackfynn-logo {
    cursor: pointer;
    text-align: left;
    height: 100%;
    padding: 0.75rem;
  }

  &__user-dropdown {
    padding: 0 1rem;
  }

  &__study-title {
    padding: 0;
    border-left: 1px solid $gaba;
    border-right: 1px solid $gaba;
    align-self: stretch;
    min-width: 15rem;

    > div {
      text-align: left;
    }

    .module {
      display: block;
    }

    .property {
      display: block;
      opacity: .75;
      margin-top: .25rem;
    }

    .module-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      padding: 0 1rem;
    }
  }

  .header__module-dropdown {
    min-width: 16.5rem;
    padding: 0 .5rem;

    /deep/ .dropdown__content {
      margin-top: 1rem;
      box-shadow: none;
    }
  }

  &__action-icons {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__action-icon-button {
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    border: none;
    margin-left: 2rem;
    padding: 0;

    &:focus,
    &:focus-within {
      outline: 1px solid white;
    }

    &::-moz-focus-inner {
      border: 0;
    }
  }

  /deep/ svg {
    fill: white;
    stroke-width: 1;
    height: 1rem;
    width: 1rem;
  }

  /deep/ .app-launcher svg {
    fill: black;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    padding-right: .5rem;

    &__action-icons {
      padding: 0;
    }

    &__action-icon-button {
      margin-left: 1rem;
    }

    &__study-title {
      min-width: 0;
    }
  }

}
</style>
