<template>
  <div
    class="modal-bg"
    @click="handleBgClick"
  >
    <div
      :class="['modal', {'fixed': fixHeaderAndFooter, 'offline': !isOnline}]"
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-content"
    >
      <div
        v-if="closeable"
        class="click-context modal__close"
        aria-label="Close modal"
        @click="close"
      >
        <SvgIcon
          name="close"
          height="1rem"
          width="1rem"
        />
      </div>
      <header
        id="modal-title"
        class="modal__title"
      >
        <slot name="title">
          {{ title }}
        </slot>
      </header>
      <section
        id="modal-content"
        class="modal__content"
      >
        <slot name="content">
          {{ content }}
        </slot>
      </section>
      <footer
        class="modal__actions"
      >
        <slot name="actions" />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    fixHeaderAndFooter: {
      type: Boolean,
      default: false
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    closeable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['isOnline'])
  },
  methods: {
    close() {
      this.$emit('close')
    },

    handleBgClick(e) {
      if (
      // Since the background element is the parent of the modal,
      // we must ensure they only clicked the background before
      // sending the close event.
        typeof e.target.className === 'string' &&
        e.target.className.includes('modal-bg') &&
        this.closeable &&
        this.closeOnClickOutside
      ) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-bg {
    position: fixed;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include elevate(lg, false);
    background-color: rgba($black, .25);
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .modal {
    position: relative;
    width: 32rem;
    max-width: calc(100% - 3rem); // creates space for the margins, when needed
    max-height: calc(100% - 3rem);
    margin: 1.5rem;
    overflow: auto;
    background-color: $white-matter;
    text-align: left;
    @include add-elevation-shadow(lg);
    display: grid;
    grid-template-rows: auto 1fr 6rem;

    &__close {
      position: absolute;
      top: .5rem;
      right: .5rem;
      padding: 0;
      margin: 0;
      @include elevate(md, false);

      &:hover {
        fill: $gaba;
        background-color: $dendrite;
        border-radius: 50%;
      }
    }

    &__title {
      grid-row: 1;
      width: 100%;
      padding: 1.5rem 2rem;
      padding-right: 3rem;
      color: $black;
      @include text-style('title', 'medium', 'medium');
      @include elevate(sm);
      border-radius: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        margin-left: -0.5rem;
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    &__content {
      grid-row: 2;
      width: 100%;
      padding: 1.5rem 2rem;
      color: $black;
      scroll-behavior: smooth;

      p {
        margin: 0;
        line-height: 1.5;
      }

      * + p {
        margin-top: .5rem;
      }
    }

    &__actions {
      grid-row: 3;
      width: 100%;
      padding: 1.5rem 2rem;
      background-color: $dendrite;
      @include elevate(sm);

      button {
        margin-right: 1rem;
      }
    }
  }

  .modal.fixed {
    overflow: hidden;

    .modal__content {
      overflow: auto;
      overflow-x: hidden;
    }
  }

  .modal.offline {
    margin-top: 3.5rem;
  }
</style>
