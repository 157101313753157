import { ppmiClinicStudyName } from '@/utils/constants'

export const hasEDCRole = user => Boolean(user.edcRoles && user.edcRoles.length)

export const hasStudyManagementRole = user =>
  Boolean(user.studyManagementRoles && user.studyManagementRoles.length)

/**
 * Get the EDC Role for a given user.
 *
 * @param {object} user - The user who's EDC role we need to find
 * @param {object} study - The study to search, defaults to Clinical
 * @returns {object} EDCRole - an EDC role object or null (if none found)
 */
export const getUserEDCRole = (user, studyName = ppmiClinicStudyName) => {
  if (!user || !hasEDCRole(user)) {
    return null
  }
  return user.edcRoles.find(role => role.studyName === studyName)
}

/**
 * Determine if the user holds the given EDC permission for the specified site and study.
 *
 * @param {object} user
 * @param {string} siteId
 * @param {string} studyId
 * @param {string} EDCPermission
 */
export const userHasEDCPermission = (user, studyId, siteId, EDCPermission) => {
  return hasEDCRole(user) &&
    user.edcRoles.some(role => {
      return role.permissions &&
        role.studyId === studyId &&
        role.siteId === siteId &&
        role.permissions.includes(EDCPermission)
    })
}

/**
 * Determine if the user holds the given CTMS permission for the specified study.
 *
 * @param {object} user
 * @param {string} studyId
 * @param {string} studyManagementPermission
 */
export const userHasStudyPermission = (user, studyId, studyManagementPermission) => {
  return hasStudyManagementRole(user) &&
    user.studyManagementRoles.some(role => {
      return role.permissions &&
        (role.studyId === studyId || !role.studyId) &&
        role.permissions.includes(studyManagementPermission)
    })
}
