<template>
  <div
    class="tooltip__hover-area"
    @mouseover="tooltipVisible = true"
    @mouseleave="tooltipVisible = false"
  >
    <slot />
    <div
      v-if="always || (tooltipVisible && condition)"
      class="tooltip"
      :class="[ placement, { 'no-offset': noOffset }, type, {'active' : isActive} ]"
    >
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    },
    condition: {
      type: Boolean,
      default: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default',
      validator: val => ['default', 'info'].includes(val)
    },
    /*
     * Tooltip will be shown permanently
     */
    always: {
      type: Boolean,
      default: false
    },
    /*
     * The tooltip accepts a placement value to define where the tooltip should be displayed in relation to the parent.
     * While we do not have the ability to determine when a tooltip is out of view and switch the placement,
     * this property can be used to manually adjust the placement as needed.
     */
    placement: {
      type: String,
      default: 'left',
      validator: val => ['top', 'right', 'bottom', 'left'].includes(val)
    },
    /*
     * This prop will remove spacing between the tooltip and the element.
     * Useful when using tooltips with icons instead of buttons or text.
     */
    noOffset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tooltipVisible: false
    }
  }
}
</script>

<style lang="scss">
  .tooltip__hover-area {
    position: relative;
  }

  .tooltip {
    position: absolute;
    height: auto;
    margin: 0;

    @include text-style('interface', 'small', 'regular');
    @include elevate(md);

    &.default {
      background-color: $black;
      color: $white-matter;
      width: 25rem;
      padding: 1rem 1.25rem;
    }

    &.info {
      padding: 0.3rem 0.7rem;
      background-color: $warning;
      color: $black;
      transform: translateX(-50%);
      white-space: nowrap;
      &.p {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
      &::after {
        border-bottom-color: $warning;
      }
    }

    p {
      margin: 0;
    }

    // use a pseudo-element to add a chat bubble effect
    &::after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      position: absolute;
      border: .5rem solid transparent;
    }

    $placement-with-offset: calc(100% + .75rem);
    &.top {
      right: 0;
      bottom: $placement-with-offset;
      &::after {
        &.info {
          border-bottom-color: $warning;
        }
        &.default {
          border-bottom-color: $black;
        }

        right: 1rem;
        bottom: -1rem;
      }
    }

    &.right {
      left: $placement-with-offset;
      bottom: -1rem;
      &::after {
        &.info {
          border-bottom-color: $warning;
        }
        &.default {
          border-bottom-color: $black;
        }

        left: -1rem;
        bottom: 1rem;
      }
    }

    &.bottom {
      left: 0;
      top: $placement-with-offset;
      &::after {
        &.info {
          border-bottom-color: $warning;
        }
        &.default {
          border-bottom-color: $black;
        }

        left: 1rem;
        top: -1rem;
      }

    }

    &.left {
      right: $placement-with-offset;
      bottom: -1rem;

      &::after {
        &.info {
          border-bottom-color: $warning;
        }
        &.default {
          border-bottom-color: $black;
        }

        right: -1rem;
        bottom: 1rem;
      }
    }

    // remove the offset spacing to bring the tooltip flush with the element
    &.no-offset {
      &.top {
        bottom: 100%;
      }
      &.right {
        left: 100%;
      }
      &.bottom {
        top: 100%;
      }
      &.left {
        right: 100%;
      }
    }
  }
</style>
