import { asNeeded } from '@/utils/constants'
import { propOr } from 'ramda'

export default {
  methods: {
    /**
     * Confirms whether form is optional or not
     * @param {Object} form
     * @returns {Boolean}
     */
    isFormOptional(form) {
      return propOr(false, 'isOptional', form)
    },
    /**
     * Appends (As Needed) to a non-required form's title
     * @param {Object} form
     * @returns {String}
     */
    appendAsNeeded(form) {
      const title = propOr('', 'title', form)
      if (this.isFormOptional(form)) {
        return `${title} ${asNeeded}`
      }
      return title
    },
    /**
     * Displays (As Needed) if form is not required
     * @param {Object} form
     * @returns {String}
     */
    displayAsNeeded(form) {
      if (this.isFormOptional(form)) {
        return asNeeded
      }
      return ''
    }
  }
}
