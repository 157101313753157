
<script>
/* eslint-disable vue/multiline-html-element-content-newline */
import SearchBox from '@/components/SearchBox/SearchBox'
import Caret from '@/components/Caret/Caret'
import StatusIcon from '@/components/StatusIcon/StatusIcon'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import EmptyState from '@/components/EmptyState/EmptyState'
import Tooltip from '@/components/Tooltip/Tooltip'
import RemoveFormModal from '@/components/RemoveFormModal/RemoveFormModal'
import SearchFilters from '@/components/SearchFilters/SearchFilters'
import searchFilterFunctionality from '@/components/SearchFilters/SearchFilterFunctionality'
import { FormStatus, VisitStatus } from '@/utils/constants'
import SkipFormModal from '@/components/SkipFormModal/SkipFormModal'
import currentUser from '@/mixins/queries/currentUser'
import openForm from '@/mixins/openForm'
import skipForm from '@/mixins/skipForm'
import appendAsNeeded from '@/mixins/appendAsNeeded'
import removeForm from '@/mixins/removeForm'
import setFormInstanceNotStarted from '@/mixins/setFormInstanceNotStarted'

import { getFormCategories } from '@/utils/form'
import { logError } from '@/utils/logging'
import { sortBy, sort, groupBy, flatten, prop, propEq, ascend, descend, isEmpty } from 'ramda'

export default {
  components: {
    SearchBox,
    Caret,
    StatusIcon,
    Tooltip,
    ContextMenu,
    EmptyState,
    SkipFormModal,
    SearchFilters,
    RemoveFormModal
  },
  mixins: [
    currentUser,
    openForm,
    skipForm,
    setFormInstanceNotStarted,
    searchFilterFunctionality,
    appendAsNeeded,
    removeForm
  ],
  props: {
    // this component will support 2 views, "detailed" & "simple"
    type: {
      type: String,
      default: 'detailed'
    },
    visitInstanceId: {
      type: String,
      default: ''
    },
    visitStatus: {
      type: String,
      default: ''
    },
    activeForm: {
      type: Object,
      default: null
    },
    formInstances: {
      type: Array,
      required: true
    },
    formVersions: {
      type: Array,
      required: true
    },
    canNavigateForms: {
      type: Boolean,
      default: false
    },
    canModifyForms: {
      type: Boolean,
      default: true
    },
    showSearchBar: {
      type: Boolean,
      default: true
    },
    externalSearchString: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    },
    visitTemplateName: {
      type: String,
      default: ''
    },
    isFormPreview: {
      type: Boolean,
      default: false
    },
    visit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      FormStatus,
      VisitStatus,
      internalSearchString: '',
      skipFormModalVisible: false,
      sortBy: {
        column: '',
        order: null
      },
      openCategories: [],
      formToSkip: {},
      formToRemove: null,
      tooltipVisible: null
    }
  },
  computed: {
    visitInstanceIdToUse() {
      return this.visitInstanceId ? this.visitInstanceId : this.$route.params.visitInstanceId
    },
    isDetailedView: function() {
      return this.type === 'detailed'
    },

    activeExpandedList() {
      return Object.keys(this.filteredForms)
    },

    sortedFormData() {
      // To accommodate filtering & sorting functionality, we need to move some properties of the formVersion
      // up to the formInstance.
      const forms = this.formInstances.map(formInstance => {
        return {
          ...formInstance,
          title: formInstance.formVersion.title,
          category: formInstance.formVersion.category
        }
      })
      return groupBy(
        form => form.category,
        sortBy(prop('position'), forms)
      )
    },

    formCategories() {
      return getFormCategories(this.sortedFormData)
    },

    /**
     * This component accepts an external search string but may also render it's own internal search state.
     * This computed prop calculates which search string to use when filtering forms.
     * @returns {string}
     */
    searchString() {
      return this.externalSearchString
        ? this.externalSearchString.toLowerCase().trim()
        : this.internalSearchString.toLowerCase().trim()
    },

    /*
     * Filters our [sorted] list of categories and forms by the
     * search string (if exists)
     */
    filteredForms() {
      let filteredForms = {}
      if (this.searchString) {
        Object.keys(this.sortedFormData).forEach(category => {
          // If the search string matches a category, display all children
          if (category.toLowerCase().includes(this.searchString)) {
            filteredForms[category] = this.sortedFormData[category]
          } else {
            const matchingForms = this.sortedFormData[category].filter(form =>
              form.title.toLowerCase().includes(this.searchString)
            )
            if (matchingForms.length) {
              filteredForms[category] = matchingForms
            }
          }
        })
      } else {
        filteredForms = Object.assign({}, this.sortedFormData)
      }

      // apply search filters
      if (this.hasSearchFiltersApplied) {
        Object.keys(filteredForms).forEach(category => {
          const matchingForms = filteredForms[category].filter(crf => this.compareToSearchFilters(crf))
          if (matchingForms.length) {
            filteredForms[category] = matchingForms
          } else {
            delete filteredForms[category]
          }
        })
      }

      return filteredForms
    },

    hasResults() {
      return !isEmpty(this.filteredForms)
    }
  },
  watch: {
    searchString() {
      if (this.searchString === '') {
        this.setOpenCategories()
      } else {
        // If there's a search string, make sure that all categories are visible
        this.openCategories = this.formCategories
      }
    },
    loading() {
      this.setOpenCategories()
    },
    sortedFormData() {
      this.generateFilterOptions()
    },

    /**
     * Watch for added/removed forms.
     * This will allow us to react to as-needed forms being added to the visit.
     * Opens the form category of the added form(s) (if closed).
     */
    formInstances(updatedValue, currentValue) {
      // If the update value length is greater than the current length, then we know that a form has been _added_.
      // We also need to make sure this doesn't fire when the value is initialized, the initial length is 0.
      if (currentValue.length > 0 && updatedValue.length > currentValue.length) {
        // How many new forms were added?
        const numOfFormsAdded = updatedValue.length - currentValue.length
        // Let's get only the added forms.
        const addedForms = updatedValue.slice(updatedValue.length - numOfFormsAdded)
        // Iterate over all the newly added forms to open their categories (if closed).
        addedForms.forEach(addedForm => {
          const newFormCategory = addedForm.formVersion.category
          // If the category for the new form is not open, add it to the open categories list.
          if (this.openCategories && !this.openCategories.includes(newFormCategory)) {
            this.openCategories.push(newFormCategory)
          }
        })
      }
    },

    /**
     * Track the open form categories in the user's session storage
     */
    openCategories: {
      deep: true,
      handler() {
        // We only want to track the open categories on the visit schedule,
        // and not the mini crf list which is primarily used for quick navigation.
        if (this.type === 'detailed' && !this.searchString && this.currentUser.id) {
          // Note: setting an item in sessionStorage will convert the value to a string.
          sessionStorage.setItem(
            `openFormCategories-${this.visitInstanceIdToUse}-${this.currentUser.id}`,
            this.openCategories
          )
        }
      }
    }
  },
  created() {
    this.setOpenCategories()
  },
  methods: {
    handleRemoveForm(formInstance) {
      // if form hasn't been started just removed it, otherwise show warning modal
      if (formInstance.status === FormStatus.NOT_STARTED) {
        this.removeForm(formInstance, this.visitInstanceId)
      } else if (this.formToRemove) {
        this.removeForm(this.formToRemove, this.visitInstanceId)
      } else {
        // displays the confirmation modal
        this.formToRemove = formInstance
      }
    },
    canRemoveForm(form) {
      return form.isOptional
    },
    canUnlockForm(form) {
      return form.status === FormStatus.NOT_COMPLETING
    },
    canSkipForm(form) {
      return form.status === FormStatus.NOT_STARTED && this.isFormSkippable(form.instanceId)
    },
    isFormClickable(form) {
      // If forms aren't navigable, then no form is clickable
      if (!this.canNavigateForms) {
        return false
      }
      // If the form is in a not-started state, then it's only clickable if we can modify forms
      if (form.status === FormStatus.NOT_STARTED) {
        return this.canModifyForms
      }
      // Otherwise, we can navigate to any form that isn't `not_completing` or skipped
      return form.status !== FormStatus.NOT_COMPLETING
    },

    closeDropdown() {
      this.$parent.closeDropdown()
    },

    // skip form, should only be used on forms with no instances
    handleSkipForm(reason) {
      this.skipForm({
        formInstanceId: this.formToSkip.id,
        visitInstanceId: this.visitInstanceIdToUse,
        reason
      })
      this.skipFormModalVisible = false
    },

    unlockForm(form) {
      this.setFormInstanceNotStarted({
        formInstanceId: form.id
      }).catch(error => {
        logError(error, 'CRFList.vue unlock form')
      })
    },

    didNotCompleteFormClick(form) {
      this.formToSkip = form
      this.skipFormModalVisible = true
    },
    /*
     * Logic to determine which categories are open by default
     */
    setOpenCategories() {
      // Retrieve the open categories from the user's session storage
      // Initially set as null and uses a try/catch block to get the value to prevent issues with mobile safari
      // and private browsing.
      let savedInSession = null
      try {
        if (this.currentUser.id) {
          savedInSession =
            sessionStorage.getItem(`openFormCategories-${this.visitInstanceIdToUse}-${this.currentUser.id}`)
        }
      } catch (error) {
        logError(error, 'CRFList.vue problem retrieving item from sessionStorage.')
      }
      if (this.activeForm) {
        // If there's an active form (this should be the "View All Forms" dropdown), then open its category
        this.openCategories = [this.activeForm.category]
      } else if (savedInSession !== null) {
        // If a matching entry in session storage exists, load it.
        // We must split the stored string value into an array.
        this.openCategories = savedInSession.split(',')
      } else {
        // Otherwise, show first section
        this.openCategories = this.formCategories[0]
      }
    },

    handleSkipFormModalClose() {
      this.skipFormModalVisible = false
      this.formToSkip = {}
    },

    /*
     * Utility methods to handle sorting on our pseudo-table
     */
    handleSortClick(column) {
      if (this.sortBy.column === column) {
        if (this.sortBy.order === 'ascending') {
          this.sortBy.order = 'descending'
        } else {
          this.sortBy.column = ''
          this.sortBy.order = null
        }
      } else {
        this.sortBy.column = column
        this.sortBy.order = 'ascending'
      }
    },

    handleClickForm(formInstance) {
      this.$emit('form-click')
      this.openFormInstance(
        formInstance,
        this.visitInstanceIdToUse,
        this.visit.isSubstudy ? this.visit.protocolName : undefined
      )
    },

    displayCaret(column) {
      if (this.sortBy.column === column) {
        return this.sortBy.order
      }
      return null
    },

    // Sorts a flat array of forms by the currently selected header.
    sortForms(forms) {
      if (this.sortBy.column) {
        return sort(this.sortBy.order === 'ascending'
          ? ascend(prop(this.sortBy.column))
          : descend(prop(this.sortBy.column))
        , forms)
      }
      return forms
    },

    getStatusLabel(form) {
      switch (form.status) {
        case FormStatus.NOT_STARTED:
          return 'Not Started'
        case FormStatus.COMPLETE:
          return 'Complete'
        case FormStatus.IN_PROGRESS:
          return 'In Progress'
        case FormStatus.NOT_COMPLETING:
          return 'Did Not Complete'
        default:
          return ''
      }
    },

    /*
     * Check a form's instances to determine if the current formInstance matches the route.
     */
    isActiveForm(formInstanceId) {
      return formInstanceId === this.$route.params.formInstanceId
    },

    /*
     * Detect if this form requires other forms to be completed before it can be started.
     */
    isFormLocked(form) {
      if (form.requiredForms.length > 0) {
        // Test if all required forms are not complete. If all are complete return opposite so isFormLocked = true
        return !form.requiredForms.every(requiredFormInstanceId => {
          const requiredFormInstance = this.formInstances.find(propEq('id', requiredFormInstanceId))
          return requiredFormInstance && requiredFormInstance.status === FormStatus.COMPLETE
        })
      } else {
        return false
      }
    },

    /*
     * Returns a string with the names of the forms required to unlock the passed form.
     */
    getRequiredForms(form) {
      let requiredFormNames = ''
      if (form.requiredForms.length > 0) {
        requiredFormNames = this.getFormTitleByInstanceId(form.requiredForms[0])
        if (form.requiredForms.length > 1) {
          for (let i = 1; i < form.requiredForms.length; i++) {
            requiredFormNames += `and ${this.getFormTitleByInstanceId(form.requiredForms[i])}`
          }
        }
      }
      return requiredFormNames
    },

    /*
     * When given a formId, return the form's title
     */
    getFormTitleByInstanceId(instanceId) {
      const instance = this.formInstances.find(propEq('id', instanceId))
      return instance ? this.formVersions.find(propEq('id', instance.formVersion.id))?.title : ''
    },

    /*
     * Generate the status filter from the CRF's returned.
     * The filter is added with unique options only, and if there exists more than 1 possible selection.
     *
     * TODO: Add filter to filter forms by User Role
     */
    generateFilterOptions() {
      const availableFilters = []

      // flatten the forms into one array to make it easier to search for filter data
      let forms = []
      Object.keys(this.sortedFormData).forEach(category => {
        forms = forms.concat(flatten(this.sortedFormData[category]))
      })

      let uniqueRoles = forms.map(form => form.formVersion.requiredUserTypes ? form.formVersion.requiredUserTypes : [])
      uniqueRoles = Array.from(new Set(flatten(uniqueRoles)))
      if (uniqueRoles.length > 1) {
        const roleOptions = this.buildOptions(uniqueRoles)
        roleOptions.unshift({ name: 'All Roles', value: '' })
        availableFilters.push(this.buildFilter('Role', 'requiredUserTypes', roleOptions))
      }

      const uniqueStatuses = Array.from(new Set(forms.map(form => form.status)))
      if (uniqueStatuses.length > 1) {
        const statusOptions = this.buildOptions(uniqueStatuses, status => this.getStatusLabel({ status }))
        statusOptions.unshift({ name: 'All Statuses', value: '' })
        availableFilters.push(this.buildFilter('Status', 'status', statusOptions))
      }

      this.filters = availableFilters
    },

    /*
     * Using the selected filter values, test if the CRF should be included or excluded.
     */
    compareToSearchFilters(crf) {
      // flatten out the crf object to make it easier to filter by nested values
      crf = { ...crf.formVersion, ...crf }
      return this.filters.every(searchFilter => {
        // if the filter is not set, return true.
        if (!searchFilter.selected.value) {
          return true
        }
        // otherwise, evaluate if the CRF matches the set filter.
        if (Array.isArray(crf[searchFilter.prop])) {
          return crf[searchFilter.prop].includes(searchFilter.selected.value)
        } else {
          return crf[searchFilter.prop] === searchFilter.selected.value
        }
      })
    },

    /**
     * Should the option to mark as "Did Not Complete" be shown?
     * note: We cannot detect whether complete log forms have new data in them from this screen.
     * Therefore, the option to switch a Complete status to Did Not Complete only exists when navigating into the form.
     * @param {object} - a form instance-like object
     * @returns {boolean}
     */
    displayMarkAsDidNotCompleteOption(form) {
      return this.isFormSkippable(form.id) && form.status === FormStatus.NOT_STARTED
    },

    /**
     * Should the option to mark as "Unlock" be shown for this form?
     * @param {object}@param {object} - a form instance-like object
     * @returns {boolean}
     */
    displayUnlockOption(form) {
      return form.status === FormStatus.NOT_COMPLETING
    },

    /**
     * If any context options need to be shown, show the context menu.
     * @param {object} - a form instance-like object
     * @returns {boolean}
     */
    formHasContextOptions(form) {
      return this.displayMarkAsDidNotCompleteOption(form) || this.displayUnlockOption(form) || this.canRemoveForm(form)
    }
  }
}
</script>

<template>
  <div>
    <SearchFilters
      v-if="filters.length && isDetailedView"
      :filters="filters"
      @filter-updated="updateSearchFilter"
    />

    <div :class="`crf-list ${type}`">
      <div class="top-bar">
        <SearchBox
          v-if="showSearchBar"
          placeholder="Find a CRF"
          :clear-search-text="type === 'detailed'"
          @input="internalSearchString = $event"
        />
        <SvgIcon
          v-if="type == 'simple'"
          class="close"
          name="close"
          @click="closeDropdown"
        />
      </div>
      <div
        v-if="isDetailedView && hasResults"
        class="crf-list__headers"
      >
        <div
          class="table-column form-name"
          @click="handleSortClick('title')"
        >
          Form Name
          <Caret :order="displayCaret('title')" />
        </div>
        <div
          class="table-column form-status"
          @click="handleSortClick('status')"
        >
          Status
          <Caret :order="displayCaret('status')" />
        </div>
        <div
          v-if="canModifyForms"
          class="table-column form-actions"
        >
          &nbsp;
        </div>
      </div>

      <el-collapse
        v-if="hasResults"
        v-loading="loading"
        :value="visitStatus === VisitStatus.IN_PROGRESS || visitStatus === VisitStatus.REOPENED || isFormPreview ?
          activeExpandedList :
          openCategories"
      >
        <el-collapse-item
          v-for="(forms, category) in filteredForms"
          :key="category"
          class="form-category"
          :title="category"
          :name="category"
        >
          <div
            v-for="form in sortForms(forms)"
            :key="form.id"
            :class="[
              'form-summary',
              isActiveForm(form.id) ? 'active-form' : '',
              isDetailedView ? 'crf-list__row' : '',
              isFormLocked(form) ? 'locked' : ''
            ]"
            @mouseover="tooltipVisible = form.id"
            @mouseleave="tooltipVisible = null"
          >
            <div :class="['form-name', isDetailedView ? 'table-column' : '']">
              <Tooltip
                class="form-status"
                :content="`This form will be unlocked when the ${getRequiredForms(form)} is complete.`"
                :condition="isFormLocked(form) && tooltipVisible === form.id"
                :no-offset="true"
                placement="right"
              >
                <StatusIcon
                  class="form-status-icon"
                  :status="isFormLocked(form) ? 'locked' : `${form.status}`"
                />
              </Tooltip>
              <a
                href="javascript:;"
                :class="['crf-link', {'link-disabled': !isFormClickable(form)}]"
                @click="isFormClickable(form) ? handleClickForm(form) : () => null"
              >
                {{ form.formVersion.title }}
                <span
                  v-if="isFormOptional(form)"
                  class="form-is-optional"
                >
                  {{ displayAsNeeded(form) }}
                </span>
              </a>
            </div>
            <div
              v-if="isDetailedView"
              class="table-column form-status"
            >
              <span
                :class="
                  ['status-wrap', {
                    'status-wrap--complete': form.status === FormStatus.COMPLETE,
                    'status-wrap--not-started': form.status === FormStatus.NOT_STARTED,
                    'status-wrap--not-completing': form.status === FormStatus.NOT_COMPLETING,
                    'status-wrap--in-progress': form.status === FormStatus.IN_PROGRESS
                  }]"
              >
                {{ getStatusLabel(form) }}
              </span>
            </div>
            <div
              v-if="isDetailedView && canModifyForms"
              class="table-column form-actions"
            >
              <ContextMenu v-if="formHasContextOptions(form)">
                <el-dropdown-item
                  v-if="displayUnlockOption(form)"
                  @click.native="unlockForm(form)"
                >
                  Unlock form
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="displayMarkAsDidNotCompleteOption(form)"
                  @click.native="didNotCompleteFormClick(form)"
                >Mark as Did Not Complete</el-dropdown-item>
                <el-dropdown-item
                  v-if="canRemoveForm(form)"
                  @click.native="handleRemoveForm(form)"
                >
                  Remove From Visit
                </el-dropdown-item>
              </ContextMenu>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div v-else>
        <EmptyState
          v-if="!!visitStatus"
          title="No Forms Found"
          image="form"
          :image-above-text="true"
          :display-in-table="true"
        >
          <template slot="subtitle">
            <p>
              Try another search term
              <a
                v-if="hasSearchFiltersApplied"
                @click="clearSearchFilters()"
              >
                or clear applied filters
              </a>
            </p>
          </template>
        </EmptyState>
        <EmptyState
          v-else
          title="Visit Not Started"
          image="form"
          :image-above-text="true"
          :display-in-table="true"
        >
          <template slot="subtitle">
            <p>
              Click the Start Visit button to begin capturing information for the {{ visitTemplateName }} visit.
            </p>
          </template>
        </EmptyState>
      </div>
    </div>

    <SkipFormModal
      v-if="skipFormModalVisible"
      :form="formToSkip"
      :form-status="formToSkip.status"
      @close="handleSkipFormModalClose"
      @skipForm="handleSkipForm"
    />

    <RemoveFormModal
      v-if="formToRemove"
      @close="formToRemove = null"
      @removeForm="removeForm(formToRemove), formToRemove = null"
    />
  </div>
</template>

<style lang="scss">

.visit-detail > .el-collapse-item >.el-collapse-item__wrap {
  background-color: transparent;
  border-bottom: none;
}

.visit-forms.reopened {
  .status-wrap {
    &--in-progress {
      background-color: $info-purple;
    }
  }
}
  .crf-list {
    &__headers,
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 .5rem;

      .table-column {
        padding: .5rem 1rem;
        width: 30%;

        .context-menu {
          &:hover svg {
            background-color: $axon;
            border-radius: 1rem;
          }
        }

        &.form-name {
          width: 45%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }

        &.form-status {
          width: 30%;
        }

        &.form-updated {
          width: 30%;
        }

        &.form-actions {
          position: relative;
          width: 5%;
          padding: 0;
          display: flex;
          justify-content: center;
        }
      }

      @media screen and  (max-width: $breakpoint-mobile) {
        padding: .5rem;
      }
    }

    &__headers {
      padding: .5rem;
      background-color: $axon;

      .table-column {
        @include text-style('interface', 'small', 'bold');
        user-select: none;
        cursor: pointer;

        &.form-name {
          justify-content: space-between;
        }
      }

      .caret-icon {
        height: 1rem;
      }
    }

    &__row {
      border-bottom: 1px solid $axon;

      &:last-child {
        border-bottom: 0;
      }

      .table-column {
        @include text-style('interface', 'small', 'medium');

        &:first-child {
          padding-left: .5rem;
        }
      }

      &:last-of-type {
        border-bottom: 0;
      }
      .status-wrap {
        display: inline-block;
        padding: .5rem 1rem;
        border-radius: 1rem;
        @include text-style('interface', 'small', 'regular');

        &--complete {
          background-color: $success-bg;
        }

        &--not-completing {
          background-color: $warning-bg;
        }

        &--in-progress {
          background-color: $notification-bg;
        }

        &--not-started {
          background-color: $axon;
        }
      }
    }

    .el-collapse {
      border-top: 1px solid $axon;
      border-bottom: none;

      // adjust spinner position to account for this view's layout
      .el-loading-spinner {
        top: 6.25rem;
      }
    }

    .el-collapse-item {
      &__header {
        position: relative;
        margin: 0;
        padding: 1rem;
        padding-left: 3rem;
        border-bottom: 1px solid $cortex;
        background-color: rgba($notification-bg, .75);
        color: $black;
        @include text-style('interface', 'small', 'bold');

        &:hover {
          color: $gaba;
        }

        @media screen and  (max-width: $breakpoint-mobile) {
          padding-left: 2.5rem;
        }

        i {
          position: absolute;
          left: 1rem;
          font-size: 1.5em;
          transform: rotate(90deg);

          &.is-active {
            transform: rotate(-90deg);
          }

          @media screen and  (max-width: $breakpoint-mobile) {
            left: .5rem;
          }
        }
      }

      &__wrap {
        border-color: $axon;

        &:last-of-type {
          border-bottom: 0;
        }
      }

      &__content {
        padding: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      // Adds top/bottom padding to forms in the list only, not headers.
      .form-name {
        padding-top: 1rem;
        padding-bottom: 1rem;

        // overrides a padding style for tooltips that increases the height of this row by a few pixels.
        .tooltip__hover-area {
          padding: 0;
        }
      }
    }

    .form-summary {
      .crf-link {
        margin-top: -1px; // fixes alignment next to icon while maintaining proper cell-width
        color: $dopamine;
        text-decoration: none;
        @include text-style('interface', 'small', 'medium');

        &.link-disabled {
          pointer-events: none;
          opacity: .75;
        }

        &:hover:not(.link-disabled), &:focus {
          color: $gaba;
        }
      }

      &.locked {
        .form-status {
          position: relative;
        }

        a {
          pointer-events: none;
        }

        svg {
          fill: $black;
          cursor: not-allowed;
        }
      }
    }

    .form-name {
      a .link-disabled {
        pointer-events: none;
        opacity: .75;
      }
    }

    .status {
      flex-shrink: 0;
      margin-right: 1rem;
      vertical-align: middle;
    }

    .form-is-optional {
      color: $hillcock;
      @include text-weight('medium');
      white-space: nowrap;
    }

    .form-status-icon {
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
      margin-right: .5rem;
      box-sizing: content-box;
      overflow: visible;
    }

    &.detailed {
      background: $white-matter;
      border: 1px solid $axon;
      border-radius: 2px;
    }

    &.simple {
      min-width: 350px;
      max-height: 525px;
      background-color: $white-matter;
      box-shadow: 1px 1px 5px rgba(0,0,0,.15);
      overflow: hidden;
      display: grid;
      grid-template-rows: auto 1fr;

      .top-bar {
        grid-row: 1;
        display: flex;
        align-items: center;

        .search-box {
          position: relative;
          flex-grow: 1;

          input {
            padding-right: 3rem;
          }
        }

        svg.close {
          position: absolute;
          right: 0;
          cursor: pointer;
          width: 3rem;
          height: 3rem;
          padding: 1rem;
        }
      }

      .el-collapse {
        grid-row: 2;
        overflow-y: auto;
        border-bottom: none;
        text-align: left
      }

      .el-collapse-item {
        &__header {
          height: auto;
        }
      }

      .form-summary {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-top: 1px solid $axon;
        padding: 0 1rem;
        @include text-weight('medium');

        &.active-form {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: $dopamine;
          }
        }
      }

      .form-name {
        display: flex;
        align-items: center;
        justify-content: flex-start
      }

      /*
       * reduce the sizing of the tooltip to fit within the dropdown
       */
      .tooltip {
        width: 18.5rem;
        padding: .5rem;
        @include text-style('interface', 'extra-small', 'regular');
      }
    }
  }
</style>
