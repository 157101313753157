/* eslint-disable */
const icon = require('vue-svgicon')
icon.register({
  'skip': {
    viewBox: '0 0 32 32',
    data: `<g>
    <path d="M17.8,25.8c-1.2,0.2-2.4,0.2-3.6,0c-0.5-0.1-1.1,0.3-1.2,0.8c-0.1,0.5,0.3,1.1,0.8,1.2c0.7,0.1,1.5,0.2,2.2,0.2
      s1.5-0.1,2.2-0.2c0.5-0.1,0.9-0.6,0.8-1.2C18.9,26.1,18.4,25.7,17.8,25.8z"/>
    <path d="M7.8,21.7c-0.3-0.5-0.9-0.6-1.4-0.3c-0.5,0.3-0.6,0.9-0.3,1.4C6.9,24,8,25.1,9.2,25.9c0.2,0.1,0.4,0.2,0.6,0.2
      c0.3,0,0.6-0.2,0.8-0.4c0.3-0.5,0.2-1.1-0.3-1.4C9.3,23.5,8.5,22.7,7.8,21.7z"/>
    <path d="M24.2,21.7c-0.7,1-1.6,1.9-2.6,2.6c-0.5,0.3-0.6,0.9-0.3,1.4c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2
      c1.2-0.8,2.2-1.9,3.1-3.1c0.3-0.5,0.2-1.1-0.3-1.4C25.2,21.1,24.5,21.2,24.2,21.7z"/>
    <path d="M26,3c-0.6,0-1,0.4-1,1v4.1C22.7,5.5,19.5,4,16,4c0,0,0,0,0,0c-3.2,0-6.2,1.3-8.5,3.5S4,12.8,4,16c0,0.6,0.4,1,1,1h0
      c0.6,0,1-0.4,1-1c0-5.5,4.5-10,10-10c3.1,0,6.1,1.5,8,4h-5c-0.6,0-1,0.4-1,1s0.4,1,1,1h8V4C27,3.4,26.6,3,26,3z"/>
  </g>`
  }
})
