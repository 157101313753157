import GET_CURRENT_USER_QUERY from '@/graphql/users/GetCurrentUserQuery.graphql'
import { logError } from '@/utils/logging'

export default {
  apollo: {
    currentUser() {
      return {
        query: GET_CURRENT_USER_QUERY,
        fetchPolicy: 'cache-first',
        update: data => data.getUser,
        error (error) {
          logError(error, 'Current user query')
        }
      }
    }
  },
  data() {
    return {
      currentUser: {}
    }
  }
}
