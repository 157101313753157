<template>
  <vue-markdown
    v-once
    class="bf-markdown"
    :postrender="trimOutput"
  >
    <slot />
  </vue-markdown>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  components: {
    VueMarkdown
  },
  methods: {
    trimOutput(output) {
      // Remove any extra whitespace, as it can affect rendering in HTML
      return output.trim()
    }
  }
}
</script>

<style lang="scss">
  // TODO: as each CRF Form is implemented, markdown styles should be added here as needed.
  .bf-markdown {
    @include text-style('paragraph', 'medium', 'regular');

    h2 {
      margin: 0;
      @include text-style('title', 'small', 'medium');
    }

    p {
      margin: 0;
      margin-top: .5rem;

      &:first-child {
        margin-top: 0;
      }
    }

    ol, ul {
      padding: 0;
      margin: 0;
    }

    ol {
      margin-top: 0;
      margin-left: 1.5rem;
    }

    ul {
      padding-left: 1rem;
    }

    ins strong {
      text-decoration: underline;
    }

    img {
      max-width: 100%;
      margin: .5rem auto;
    }

    // Text using code syntax should be using the underline syntax instead. This is a data issue.
    // TODO: Once the form defintion is corrected, remove this fix.
    code {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
      text-decoration: underline;
    }
  }
</style>
