<script>
/**
 * ToastTray: A component to create a display area for toast notifications
 * Toast notifications are managed in the Vuex store.
 */
import BfAlert from '@/components/BfAlert/BfAlert'
import BfMarkdown from '@/components/BfMarkdown/BfMarkdown'

export default {
  components: { BfAlert, BfMarkdown },
  computed: {
    /**
     * Retrieve the toast messages from the store.
     *
     * @returns {array} - an array of toast messages
     */
    toastMessages() {
      return this.$store.state.toastModule.toastMessages || []
    }
  },
  methods: {
    /**
     * Calculate the icon to use for a toast message based on the toast "type".
     *
     * @param {string} toastType - the type of toast message to display
     */
    getToastIcon(toastType) {
      switch (toastType) {
        case 'error':
          return 'error'
        case 'success':
          return 'checkmark'
        case 'info':
        default:
          return 'warning'
      }
    },

    /**
     * Dispatch a Vuex action to remove a toast message.
     *
     * @param {object} toastId - the ID of a toast message to remove
     */
    dismissNotification(toastId) {
      this.$store.dispatch('removeToastMessage', toastId)
    }
  }
}
</script>

<template>
  <div :class="['toast-tray', { 'full-width': $route.meta.showHeader === false }]">
    <!-- Using slice() we can grab the latest 3 toastMessages -->
    <BfAlert
      v-for="toastMessage in toastMessages.slice(-3)"
      :key="toastMessage.id"
      :type="toastMessage.type || 'info'"
      :icon="getToastIcon(toastMessage.type)"
      :closeable="true"
      role="status"
      aria-live="polite"
      @dismiss="dismissNotification(toastMessage.id)"
    >
      <!-- We utilize markdown so that we may render links -->
      <BfMarkdown>{{ toastMessage.text }}</BfMarkdown>
    </BfAlert>
  </div>
</template>

<style lang="scss" scoped>
.toast-tray {
  position: fixed;
  width: calc(100% - 3rem);
  z-index: $elevation-lg;

  display: grid;
  grid-template: auto / 1fr;
  grid-gap: .5rem;

  .bf-alert {
    margin-top: 0;
    border: none;
    padding-top: .25rem;
    padding-bottom: .25rem;
    @include elevate('sm');

    &:nth-child(3) + .bf-alert {
      transition: none;
    }
  }

  &.full-width {
    width: 100%;
  }
}
</style>
