import { ParticipantProtocolStatus } from '@/utils/constants'
import { defaultTo } from 'ramda'

/**
 * Retrieve the participant's full name, if available, otherwise show the customerDefinedId
 * NB: this component is used on both the EDC and CTMS sides of the application
 * on the CTMS, we (should) only have access to the customerDefinedId (via a RestrictedParticipant)
 * on the EDC, we have access to the full name (via a unrestricted Participant)
 * @returns {string} - the participant's full name or PPMI ID (depending on active module)
 */
export const getParticipantNameOrPPMIId = (participant) => {
  const _participant = defaultTo({ customerDefinedId: '' }, participant)
  return _participant.firstName
    ? `${_participant.firstName} ${_participant.familyName}`
    : _participant.customerDefinedId
}

/**
 * Get the participant status.
 * Note: This will always be the first protocol since we are passing a studyId.
 * TODO: Update once sub-studies are applied to ensure we default to _clinical_ status.
 * TODO: Add option to get status by specified protocol.
 * @param {object} participant - a participant object.
 * @returns {string} - the given participant's clinical status
 */
export const getParticipantStatus = (participant) => {
  if (!participant) { return }
  return participant.protocols
    ? participant.protocols[0].status
    : ParticipantProtocolStatus.PENDING
}

/**
 * Determine if the given participant is excluded.
 * TODO: Update after sub-studies are supported
 * @param {object} participant - a participant object.
 * @returns {string} - the given participant's clinical status
 */
export const isParticipantExcluded = (participant) => {
  const participantProtocolStatuses = [
    ParticipantProtocolStatus.EXCLUDED,
    ParticipantProtocolStatus.SCREEN_FAILED,
    ParticipantProtocolStatus.WITHDREW
  ]
  const participantStatus = getParticipantStatus(participant)
  return participantProtocolStatuses.includes(participantStatus)
}

/**
 * Retrieve the participant's schedule base date.
 * @param {object} participant
 * @returns {string} - the schedule base date, represented as a date string
 */
export const getParticipantScheduleBaseDate = (participant) => {
  return participant.protocols ? participant.protocols[0].scheduleBaseDate : null
}

/**
 * Returns the participant's enrollment subgroups as string.
 * @returns {string} - comma seperated list of enrollment subgroups
 */
export const getParticipantEnrollmentSubgroups = (participant) => {
  if (participant && participant.cohort && participant.cohort.subgroups.length) {
    return participant.cohort.subgroups.map(subgroup => subgroup.name).join(', ')
  } else {
    return 'No Subgroup'
  }
}
