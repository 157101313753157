<template>
  <div
    id="app"
    :class="{ 'no-header': $route.meta.showHeader === false }"
  >
    <BfAlert
      v-if="!isOnline"
      type="error"
      class="top-banner offline-banner"
      icon="warning"
    >
      You're currently offline. No data will be saved until your connection is restored.
    </BfAlert>
    <BfAlert
      v-if="updateExists"
      type="error"
      class="top-banner update-available-banner"
      icon="warning"
      @click.native="refreshApp"
    >
      A new version of the EDC is available. <a @click="refreshApp">Update Now</a>
    </BfAlert>
    <div
      v-if="$route.meta.showHeader !== false"
      class="fixed-header"
    >
      <TheHeader @signout="handleSignout" />
      <router-view name="subheader" />
    </div>
    <main
      v-loading="loading"
      class="main-content"
    >
      <router-view name="sidebar" />
      <div class="padding-wrap">
        <ToastTray />
        <router-view
          role="tabpanel"
          aria-expanded="true"
        />
      </div>
    </main>
    <OfflineModal :online="isOnline" />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import ToastTray from './components/ToastTray/ToastTray'
import OfflineModal from '@/components/OfflineModal/OfflineModal'
import BfAlert from '@/components/BfAlert/BfAlert'
import { throttle } from 'throttle-debounce'
import { getHoursDifference } from '@/utils/date'
import apolloUtilities from '@/mixins/apolloUtilities'
import Auth from '@aws-amplify/auth'
import { mapState, mapActions } from 'vuex'
import utilities from '@/mixins/utilities'

const HOURS_UNTIL_IDLE_LOGOUT = 8

export default {
  name: 'Home',
  components: {
    TheHeader,
    ToastTray,
    OfflineModal,
    BfAlert
  },
  mixins: [ apolloUtilities, utilities ],
  data () {
    return {
      activeTab: 'studies',
      loggedIn: false,
      refreshing: false,
      serviceWorkerRegistration: null,
      updateExists: false,
      lastActivityDateTime: new Date()
    }
  },
  computed: {
    ...mapState(['loading', 'isOnline'])
  },
  watch: {
    lastActivityDateTime(oldDate, newDate) {
      if (getHoursDifference(oldDate, newDate) >= HOURS_UNTIL_IDLE_LOGOUT) {
        this.handleSignout()
          .then(() => {
            this.addToastMessage({
              text: 'For security purposes, you have been logged out due to inactivity.',
              dismissAfter: 0
            })
          })
      }
    }
  },
  async created() {
    // listeners to detect activity, used to detect if the user is idle
    window.addEventListener('mousemove', throttle(1000, this.activityDetectedHandler))
    window.addEventListener('keydown', throttle(1000, this.activityDetectedHandler))
    window.addEventListener('mousedown', throttle(1000, this.activityDetectedHandler))
    window.addEventListener('touchstart', throttle(1000, this.activityDetectedHandler))

    // listen to an event fired from registerServiceWorker.js telling us a service worker update is available
    window.document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    if (process.env.NODE_ENV !== 'test') {
      // Prevent multiple refreshes
      window.navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
    }
    try {
      await Auth.currentAuthenticatedUser()
    } catch {
      // Explicity do nothing on error - the user is just not logged in
    }
  },
  methods: {
    ...mapActions(['addToastMessage']),
    handleSignout() {
      return Auth.signOut().then(() => {
        this.clearApolloCache()

        return this.$router.push({ name: 'login' })
      })
    },
    activityDetectedHandler() {
      this.lastActivityDateTime = new Date()
    },
    // store the service worker so we can send it a message
    updateAvailable(event) {
      this.serviceWorkerRegistration = event.detail

      // If application is PPMI then force update/refresh, if newer version is found
      if (this.isApplicationPPMI()) {
        this.refreshApp()
      } else {
        this.updateExists = true
      }
    },
    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false
      this.clearSessionStorage()
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.serviceWorkerRegistration || !this.serviceWorkerRegistration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    /**
     * Clear the session storage.
     * Does not clear apollo session storage.
     */
    clearSessionStorage() {
      Object.keys(sessionStorage).forEach(key => {
        // clear the openFormCategories state
        if (!key.startsWith('reduxPersist')) {
          sessionStorage.removeItem(key)
        }
      })
    }
  }
}
</script>

<style lang="scss">
// define CSS custom props
:root {
  /* These custom props define our elevation levels. */
  --elevation-sm: #{$elevation-sm};
  --elevation-md: #{$elevation-md};
  --elevation-lg: #{$elevation-lg};
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

svg {
  height: 2rem;
  width: 2rem;
}

html {
  height: 100%;
  font-size: 16px;

  @media print {
    height: auto;
  }
}

body {
  overflow: hidden;
  height: 100%;
  margin: 0px;
  background-color: $dendrite;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media print {
    height: auto;
    overflow: visible;
    background-color: $white-matter;
  }
}

input,
button {
  // Chrome's browser styles need to be more explicitly overriden for certain element types.
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
}

#app {
  text-align: center;
  color: $black;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media print {
    height: auto;
    display: block;
  }
}

.top-banner {
  border: none;
  margin-top: 0;
  padding-left: 1rem;
  // primarily to get over modal backgrounds, should be over anything
  z-index: 999;

  .bf-alert__content {
    text-align: initial
  }
}

.update-available-banner {
  cursor: pointer;
}

.authenticate {
  height: 100%;
}

.fixed-header {
  flex: 0 1 auto;
}

.main-content {
  flex: 1 1 auto;
  overflow: auto;
  width: 100%;
  text-align: initial;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  position: relative;

  @media print {
    display: block;
  }
}

.padding-wrap {
  flex: 1;
  padding: 1.5rem;
  overflow-x: hidden;
  overflow-y: visible;

  @media print {
    flex: none;
    padding: 0 1rem;
  }
}

.no-header {
  .padding-wrap {
    padding: 0;
  }
}

svg,
figure,
img {
  display: block;
}

svg {
  &.flip {
    transform: scaleX(-1);
  }
}

a {
  cursor: pointer;
  outline: none;
  color: $dopamine;
  text-decoration: underline;
  @include text-weight('medium');

  &:hover, &:focus, &:active {
    color: $gaba;
  }
}

/*
 * Utility class: used to provide an icon with an accessible click area.
 * Provides a 44x44px parent that centers the child.
 */
.click-context {
  min-width: 2.75rem;
  min-height: 2.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/**
 * Hides elements visually but provides them to screen readers.
 * @see https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
 */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: "rect(0 0 0 0)";
  clip-path: inset(50%);
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.print-only {
  display: none;

  @media print {
    display: inherit;
  }
}

// General Print Rules
@media print {
  // This trick removes the browser default of displaying title, url, date and page number in the header and footer
  // of the printed page. Unfortunately, Safari does not support this out of the box (power users can enable it).
  // Users will need to select the "No Headers & Footers" print option in Safari to achieve the same effect.
  @page {
    size: auto;
    margin: 0;
  }

  .not-for-print,
  .fixed-header {
    display: none;
  }

  // Since a print-out contains no interactivity, use a black-and-white color scheme
  a {
    color: $black !important;
  }

  svg {
    fill: $black !important;
  }
}
</style>
