const featureFlagModule = {
  state: {
    activeSite: {}
  },

  mutations: {
    /**
     * Update the store with a new active site.
     *
     * @param {object} - The Vuex state
     * @param {object} - The active site expressed as an object.
     */
    UPDATE_ACTIVE_SITE(state, site) {
      state.activeSite = { ...site }
    }
  },

  actions: {
    /**
     * An action to initiate the update active site mutation
     *
     * @param {object} context - A Vuex context object
     * @param {symbol} site - The site which should be made "active"
     */
    updateActiveSite({ commit, dispatch }, site) {
      commit('UPDATE_ACTIVE_SITE', site)
    }
  },
  getters: {
    supportedFeatures: state => {
      /**
       * Enabled feature flags
       */
      return state.activeSite.features || []
    }
  }
}

export default featureFlagModule
