import { ModuleLabels } from '@/utils/constants'

export default {
  computed: {
    detectModule() {
      if (this.$route.path.startsWith('/ctms')) {
        return ModuleLabels.CTMS
      } else if (this.$route.path.startsWith('/edc')) {
        return ModuleLabels.EDC
      }
      // fallback return for routes like /user that have no module.
      return null
    },
    isEDCActive() {
      return this.detectModule === ModuleLabels.EDC
    },
    isCTMSActive() {
      return this.detectModule === ModuleLabels.CTMS
    }
  }
}
