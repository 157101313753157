import Vue from 'vue'
import Vuex from 'vuex'

// Retrieve Vuex modules
import toastModule from './toastModule'
import flashMessageModule from './flashMessageModule'
import featureFlagModule from './featureFlagModule'

Vue.use(Vuex)

// Setup global store
const state = {
  isOnline: navigator.onLine,
  loading: false,
  isSidebarOpen: false,
  /*
    * Not sure what format this data will be delivered in.
    * For now, I'm just using a string.
    * TODO: update this, probably
    */
  redirectPage: null
}

const mutations = {
  LOADING_START(state) {
    state.loading = true
  },
  LOADING_END(state) {
    state.loading = false
  },
  SET_ONLINE_STATUS(state, payload) {
    state.isOnline = payload
  },
  SET_SIDEBAR_STATUS(state, payload) {
    state.isSidebarOpen = payload
  },
  SET_REDIRECT_PAGE(state, payload) {
    state.redirectPage = payload
  }
}

const actions = {
  updateLoading: ({ commit }, payload) => {
    if (payload === true) {
      commit('LOADING_START')
    } else {
      commit('LOADING_END')
    }
  },
  updateOnlineStatus: ({ commit }, payload) => commit('SET_ONLINE_STATUS', payload),
  updateSidebarStatus: ({ commit }, payload) => commit('SET_SIDEBAR_STATUS', payload),
  updateRedirectPage: ({ commit }, payload) => commit('SET_REDIRECT_PAGE', payload),
  refreshVisitWindow: () => true
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    toastModule,
    flashMessageModule,
    featureFlagModule
  }
})

export default store
