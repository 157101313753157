<template>
  <div class="skip-form-modal">
    <Modal
      v-if="warningModalVisible"
      title="Reason Form Wasn't Completed"
      content="You have added data to this form. Marking this form as 'Did not complete'
      will erase any data collected in this form. This cannot be undone. Are you sure you would like to continue?"
      @close="$emit('close')"
    >
      <template v-slot:actions>
        <el-button
          type="primary"
          @click="warningModalVisible = false, reasonModalVisible = true"
        >
          Continue
        </el-button>
        <el-button
          type="outline"
          @click="$emit('close')"
        >
          Cancel
        </el-button>
      </template>
    </Modal>
    <Modal
      v-if="reasonModalVisible"
      title="Reason Form Wasn't Completed"
      @close="$emit('close')"
    >
      <template v-slot:content>
        <BfSelect
          v-model="reasonSelected"
          label="Reason"
          placeholder="Select reason"
          popper-class="form-not-complete-reason-dropdown"
        >
          <el-option
            v-for="(reason, index) in reasonsNotCompleted"
            :key="index"
            :label="reason.reason"
            :value="reason.reason"
          />
        </BfSelect>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="`${showReasonComment ? 'required' : ''}`"
        >
          <BfTextArea
            v-if="showReasonComment"
            v-model="reasonNotCompleteComment"
            :error="errors[0]"
            class="skip-reason-textarea"
            label="Please specify*"
          />
        </ValidationProvider>
      </template>
      <template v-slot:actions>
        <el-button
          :disabled="isSkipFormSubmitDisabled"
          type="primary"
          @click="handleSkipForm"
        >
          Submit
        </el-button>
        <el-button
          type="outline"
          @click="$emit('close')"
        >
          Cancel
        </el-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import BfSelect from '@/components/BfSelect/BfSelect'
import BfTextArea from '@/components/BfTextArea/BfTextArea'
import Modal from '@/components/Modal/Modal'
import FORM_SKIP_REASONS_QUERY from '@/graphql/forms/FormSkipReasonsQuery.graphql'
import { FormStatus } from '@/utils/constants'
import { propEq } from 'ramda'

export default {
  components: {
    BfSelect,
    BfTextArea,
    Modal
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    formStatus: {
      type: String,
      default: ''
    }
  },
  apollo: {
    reasonsNotCompleted: {
      query: FORM_SKIP_REASONS_QUERY,
      update: data => data.getFormSkipReasons
    }
  },
  data() {
    return {
      reasonsNotCompleted: [],
      reasonSelected: null,
      reasonNotCompleteComment: '',
      warningModalVisible: this.formStatus === FormStatus.IN_PROGRESS,
      reasonModalVisible: this.formStatus !== FormStatus.IN_PROGRESS
    }
  },
  computed: {
    isSkipFormSubmitDisabled() {
      return !this.reasonSelected || (this.showReasonComment && !this.reasonNotCompleteComment.trim())
    },
    showReasonComment() {
      const reason = this.reasonsNotCompleted.find(propEq('reason', this.reasonSelected))
      return reason && reason.commentRequired
    }
  },
  methods: {
    handleSkipForm() {
      this.$emit('skipForm', this.showReasonComment
        ? { reason: this.reasonSelected, comment: this.reasonNotCompleteComment }
        : { reason: this.reasonSelected }
      )
    }
  }
}
</script>
<style>
.skip-reason-textarea {
  margin-top: 1rem;
}
</style>
