<template>
  <div
    :class="[
      'dropdown',
      {'dropdown--right': orientation === 'right'},
      {'dropdown--left': orientation === 'left'}]"
  >
    <div
      v-click-outside="handleClickOutside"
      :class="['dropdown__title-wrap', { readonly: readOnly }]"
      role="button"
      @click="toggle"
      @mouseover="openOnHover"
      @mouseleave="closeOnLeave"
    >
      <slot name="title">
        <SvgIcon
          v-if="icon"
          :icon="icon"
          class="dropdown__title-icon"
        />
        <span
          v-if="title"
          class="dropdown__title"
        >{{ title }}</span>
      </slot>
      <Caret
        v-if="!readOnly"
        :order="caretPosition"
      />
    </div>
    <div
      v-if="open && !readOnly"
      class="dropdown__content"
      @click.stop
    >
      <slot />
    </div>
  </div>
</template>
<script>
import Caret from '@/components/Caret/Caret'
import DropdownItem from './DropdownItem'

export default {
  name: 'Dropdown',
  components: {
    Caret
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: undefined
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: false
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false
    },
    orientation: {
      type: String,
      default: 'right',
      validator: val => ['right', 'left'].includes(val)
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    caretPosition() {
      if (this.open) {
        return 'ascending'
      }
      return 'descending'
    }
  },
  methods: {
    handleClickOutside(e) {
      if (this.closeOnClickOutside) {
        this.open = false
      }
    },
    toggle() {
      if (!this.readOnly) {
        this.open = !this.open
      }
    },
    openOnHover() {
      if (this.hoverable) {
        this.open = true
      }
    },
    closeOnLeave() {
      if (this.hoverable) {
        this.open = false
      }
    },
    closeDropdown() {
      this.open = false
    }
  },
  DropdownItem
}
</script>
<style lang="scss" scoped>
.dropdown {
  position: relative;
  height: 100%;
  display: flex;
  // setting reference to use in other selectors below
  $this: &;

  &__title {
    padding: 0 .5rem;
  }

  &__title-icon {
    display: inline-block
  }

  &__content {
    position: absolute;
    min-width: 100%;
    top: 100%;
    @include elevate(md);

    #{$this}--right & {
      left: 0
    }

    #{$this}--left & {
      right: 0
    }
  }

  &__title-wrap {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    &.readonly {
      cursor: initial;
    }
  }

  /deep/ .caret-icon {
    width: initial;

    svg {
      position: initial;
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
