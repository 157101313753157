/* Date formatting utilities */

/**
 * returns an object with string representations of the year, month, and day of the passed date
 * @param {(String|Date)} date a date object or YYYY-MM-DD string
 * @param {Boolean} displayFull flag for displaying month as a word and stripping day of any leading 0
 * @return {Object} { day: string ("8"), month: string ("8" or "August"), year: number (2020)}
 */
export function getDateParts(date, displayFull = false) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  // if we're passed a string, extract the date if its in a YYYY-MM-DD format
  /**
   * we want to get the values straight from the string if possible
   * since creating a new date from a string can introduce timezone issues
   * possibly resulting in a date +/- 1 day from where we want
   */
  if (typeof date === 'string' && /\d\d\d\d-\d\d-\d\d/.test(date)) {
    // its not perfect, but if its a dddd-dd-dd format we assume its yyyy-mm-dd
    const dateParts = splitDateFromAPI(date)

    return {
      // strip any leading 0 from day if displaying full date, for ~aesthetic~ purposes
      day: displayFull ? String(Number(dateParts.day)) : dateParts.day,
      month: displayFull ? months[Number(dateParts.month) - 1] : dateParts.month,
      year: dateParts.year
    }
  } else {
    // if its not in a dddd-dd-dd format, we can attemp to parse an arbitrary string/date
    const d = new Date(date)
    const year = d.getFullYear()
    let day = '' + d.getDate()
    let month = d.getMonth()

    if (!displayFull) {
      month = '' + (month + 1)
      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }
    } else {
      month = displayFull ? months[month] : month
    }

    return {
      day,
      month,
      year
    }
  }
}

/*
 * This function formats the date in the correct format accepted by our API.
 * ex: YYYY-MM-DD
 */
export const formatDateForAPI = (date) => {
  const { day, month, year } = getDateParts(date)
  return [year, month, day].join('-')
}

/*
 * This function formats a date for short date display
 * ex: MM-DD-YYYY
 */
export const formatDateForDisplay = (date, seperator = '-') => {
  const { day, month, year } = getDateParts(date)
  return [month, day, year].join(seperator)
}

/*
 * This function formats a date for the most human-readable display
 * ex: January 1, 2020
 */
export const displayFullDate = (date) => {
  const { day, month, year } = getDateParts(date, true)
  return `${month} ${day}, ${year}`
}

export const displayAbbreviatedDate = (date) => {
  const { day, month, year } = getDateParts(date, true)
  return `${month.substring(0, 3)} ${day}, ${year}`
}

/*
 * This function returns the year, date, and month strings from a date string returned from the API
 * ex: YYYY-MM-DD => { year, month, day } ex. { year: '2020', month: '07', day: '08'}
 */
export const splitDateFromAPI = (dateString) => {
  const [ year, month, day ] = dateString.split('-')
  return {
    year,
    month,
    day
  }
}

/**
 * get the number of hours difference between two dates
 * @param {Date} date1
 * @param {Date} date2
 */
export const getHoursDifference = (date1, date2) => {
  return Math.abs(date1.getTime() - date2.getTime()) / 3600000
}

/*
 * This function rearranges the date format returned from the API
 * ex: YYYY-MM-DD => MM-DD-YYYY
 */
export const displayDateFromAPI = (date) => {
  const { year, month, day } = splitDateFromAPI(date)
  return [month, day, year].join('-')
}

/*
 * Display date in the expected format of the datepicker
 * ex: MM/DD/YYYY
 */
export const displayDateForDatepicker = (date) => {
  const { day, month, year } = getDateParts(date)
  return [month, day, year].join('/')
}

/**
 * Date manipulation utilities
 */
export const datePlusDays = (date, days) => {
  const newDate = new Date(date)
  return newDate.setDate(newDate.getDate() + days)
}

export const getUTCDate = (date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}

export const getAge = (dateString) => {
  const today = new Date()
  const birthDate = getUTCDate(new Date(dateString))
  let age = today.getFullYear() - birthDate.getFullYear()
  const months = today.getMonth() - birthDate.getMonth()

  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  return age
}

const allowedYearsBefore = 100
const allowedYearsAfter = 0

function getDateWithYearOffset(offset) {
  const dateWithOffset = new Date()
  dateWithOffset.setFullYear(dateWithOffset.getFullYear() + offset)
  return dateWithOffset
}

export const getDefaultMinDate = (overrideOffset) => {
  return getDateWithYearOffset(-(overrideOffset || allowedYearsBefore))
}

export const getDefaultMaxDate = (overrideOffset) => {
  return getDateWithYearOffset(overrideOffset || allowedYearsAfter)
}
