import Vue from 'vue'
import {
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Input,
  Collapse,
  CollapseItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Loading,
  Alert,
  DatePicker,
  TimePicker,
  Radio,
  RadioGroup,
  RadioButton,
  Upload,
  Tooltip,
  Popover
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Input)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Loading)
Vue.use(Alert)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Upload)
Vue.use(Tooltip)
Vue.use(Popover)
