
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormInstance"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormInstance"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"requiredForms"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"assessmentDate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"reason"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"comment"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isOptional"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"formVersion"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormVersion"},"directives":[]}]}}]}}],"loc":{"start":0,"end":243}};
    doc.loc.source = {"body":"#import \"./FormVersionFragment.graphql\"\n\nfragment FormInstance on FormInstance {\n    id,\n    position,\n    requiredForms,\n    status,\n    assessmentDate,\n    reason,\n    comment,\n    isOptional,\n    formVersion {\n        ...FormVersion\n    }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./FormVersionFragment.graphql").definitions));


      module.exports = doc;
    
